import { ReactElement } from "react";

import Settings from "../services/settings";

import { useUser } from "./useUser";

const accessLevel = {
  "Gestor": 100,
  "Secretário": 80,
  "Professor": 10,
} as const;

export class AccessComponent {
  static whoCantAccessOnly = WhoCantAccessOnly;
  static whoCanAccessOnly = WhoCanAccessOnly;
  static hasAtLeastAccessLevel = HasAtLeastAccessLevel;
  static whoAmI = WhoAmI;
  static getMySchoolName = Settings.getSettings()?.accountsDetails?.length > 0 ? Settings.getSettings()?.accountsDetails[0]?.stripe?.name : ''
}

function WhoAmI(): keyof typeof accessLevel | null {
  const { userAccessLevel, loadedAccessLevel } = useUser();
  if (!loadedAccessLevel) {
    return null;
  }

  for (const key in accessLevel) {
    if (accessLevel[key] === userAccessLevel) {
      return key as keyof typeof accessLevel;
    }
  }
  return "Professor";
}

export function TranslateAccessLevel(accessLevelString: number | string) {
  for (const key in accessLevel) {
    if (accessLevel[key] === accessLevelString) {
      return key as keyof typeof accessLevel;
    }
  }
  return "Professor";
}

function WhoCantAccessOnly(props: {
  cantAccess: keyof typeof accessLevel[],
  children: React.ReactNode
}
) {
  const { userAccessLevel } = useUser();
  if (accessLevel[props.cantAccess] === userAccessLevel) {
    return null;
  }
  return props.children;
}

function WhoCanAccessOnly(props: {
  canAccess: keyof typeof accessLevel | keyof typeof accessLevel[],
  children: ReactElement<any, any> | ReactElement<any, any>[]
}
): ReactElement<any, any> | null {
  const { userAccessLevel } = useUser();
  if (accessLevel[props.canAccess] === userAccessLevel) {
    return <>{props.children}</>;
  }
  return null;
}

function HasAtLeastAccessLevel(props: {
  atLeast: keyof typeof accessLevel[],
  children: ReactElement<any, any> | ReactElement<any, any>[]
}
) {
  const { userAccessLevel } = useUser();
  if (accessLevel[props.atLeast] <= userAccessLevel) {
    return <>{props.children}</>;
  }
  return null;
}
