import { useQuery } from '@tanstack/react-query';

import { IMissaoComprovante } from "../interfaces/missao_comprovante";
import Client from "../services/client";
import Settings, { ISettings } from "../services/settings";

import { ACCESS_LEVELS, useUser } from "./useUser";



const client = new Client();

export function useMissoes() {
  return useQuery({
    initialData: [],
    queryKey: ["missions"],
    queryFn: async () => {
      const response = await Client.authenticatedRequest({
        method: "GET",
        path: "missoes2/get-all"
      });
      return response.data as Missao[];
    },
  })
}

export const useAllPlayers = () => {
  return useQuery({
    initialData: [],
    queryKey: ["players"],
    queryFn: async () => await client.getPlayers()
  })
}

type Reward = {
  coin: number
  xp: number
}

export type Players = {
  id: string
  firstName: string
  surname: string
  accessHash: string
  class: Array<{
    id: string
    name: string
  }>
  games: Array<{
    id: string
    name: string
    points: Reward
    rewardsToCollect: Reward
    runningQuests: {
      [key: string]: number;
    };
    level: {
      id: string
      progress: number
      source: string
      name: string
      order: number
    }
    rewardsToCollectFromMissions: object
    rewardsTocollectfromSald: Reward
    totalRewardsToCollectFromMissions2: object | undefined
  }>
}

export function usePlayers() {

  const { userAccessLevel } = useUser();

  const settings = Settings.getSettings();

  const getLevelIcon = (game: string | number, level: any) => {
    if (!settings.levels) {
      return null;
    }
    const levels = settings.levels;
    let source: any, name: any, order: any;
    for (const obj of levels[game]) {
      if (obj.id === level) {
        source = obj.source;
        name = obj.name;
        order = obj.order;
      }
    }
    return { source, name, order };
  };

  return useQuery({
    initialData: [],
    queryKey: ["players-hook"],
    queryFn: async () => {
      const result =
        userAccessLevel >= ACCESS_LEVELS.SECRETARY
          ? await client.getPlayers()
          : await client.getPlayersByTeacher();

      for (const player of result) {
        for (const game of player.games) {
          const levelInfo = getLevelIcon(game.id, game.level.id);
          game.level.source = levelInfo.source;
          game.level.name = levelInfo.name;
          game.level.order = levelInfo.order;
        }
      }

      return result as Players[]
    }
  })
}

export function useUsers() {
  type Users = {
    "_id": string,
    "email": string,
    "verified": boolean,
    "accounts": string[],
    "groups": string[],
    "createdAt": string,
    "updatedAt": string,
    "lastLoginAt": string,
    "birthDate": string,
    "gender": string,
    "name": string,
    "phone": string
  }

  return useQuery({
    initialData: [],
    queryKey: ["users"],
    queryFn: async () => await client.getUsers<Users[]>()
  })
}

export function useMyClassses() {
  return useQuery({
    initialData: [],
    queryKey: ["my-classes"],
    queryFn: async () => await client.getClassesByTeacher()
  })
}


export type Game = {
  _id?: string
  id: string
  name: string
  worldId?: string
  points: {
    coin: number
    xp: number
  }
  coinsLimit?: number
}
export function useGames() {


  return useQuery({
    initialData: [],
    queryKey: ["games"],
    queryFn: async () => await client.getGames() as Game[],
  })
}

export type Class = {
  id: string
  worldId: string
  name: string
  games: Array<string>
  createdAt: string
  updatedAt: string
}

export function useAllClasses() {
  return useQuery({
    queryKey: ["all-classes"],
    queryFn: async () => await client.getClasses(),
    initialData: []
  })
}

export function useClassesByTeacher() {
  return useQuery({
    queryKey: ["teacher-classes"],
    queryFn: async () => await client.getClassesByTeacher(),
    initialData: []
  })
}

export function useClasses() {
  const { userAccessLevel } = useUser();

  return useQuery({
    initialData: [],
    queryKey: ["classes"],
    queryFn: async () => {
      if (userAccessLevel >= ACCESS_LEVELS.SECRETARY) {
        return await client.getClasses() as Class[];
      }
      return await client.getClassesByTeacher() as Class[];
    }
  })
}

export const useClassById = (classId: string) => {
  return useQuery({
    queryKey: ["class-by-id", classId],
    queryFn: async () => await client.getClass(classId) as Class
  })
}

export const useProofs = () => {
  return useQuery({
    initialData: [],
    queryKey: ["proofs"],
    queryFn: async () => {
      const response = await Client.authenticatedRequest({
        method: "GET",
        path: "missoes2/comprovantes/get-all"
      });
      return response.data as IMissaoComprovante[];
    }
  })
}

export const useSettings = () => {
  return useQuery({
    initialData: {} as ISettings,
    queryKey: ["settings"],
    queryFn: async () => await client.getSettings() as ISettings
  })
}

export const useGiftCards = () => {
  return useQuery({
    queryKey: ["gift-cards"],
    queryFn: async () => {
      const result = await client.getGiftCards();
      return result.filter(giftCard => giftCard.quantity > 0);
    },
    initialData: []
  })
}

export const useAvailableGiftCards = () => {
  return useQuery({
    queryKey: ["available-gift-cards"],
    queryFn: async () => await client.getGiftCardsAvailabes(),
    initialData: []
  })
}

export const useRewards = () => {
  return useQuery({
    queryKey: ["rewards"],
    queryFn: async () => await client.getItems(),
    initialData: []
  })
}

export const useWallet = () => {
  return useQuery({
    queryKey: ["wallet"],
    queryFn: async () => await client.getWallet(),
    initialData: []
  })
}

export const useWalletStatments = (level: number) => {
  return useQuery({
    queryKey: ["wallet-statments"],
    queryFn: async () => await client.getWalletStatment(level),
    initialData: []
  })
}

export const useShoppingPurchases = () => {
  return useQuery({
    queryKey: ["shopping-purchase"],
    queryFn: async () => await client.getShoppingPurchases(),
    initialData: [],
  })
}
