 
import { useEffect, useState } from "react";

import { DialogActions, DialogContent } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog/Dialog";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import { css } from "emotion";

import { AccessComponent } from "../../../context/onlyUserType";
import Client from "../../../services/client";
import Settings from "../../../services/settings";
import ContainerCard from "../../atoms/container-card";
import { Constants } from "../../molecules/advanced-settings-form";
import PlayersListItem from "../player-list-item";
import { DefaultButtonCss } from "../players-actions";
const style = css`
  .options {
    display: flex;
    justify-content: flex-end;
  }
  .show-only-in-selected {
    display: none;
  }
  .hide-options {
    .options {
      opacity: 0;
    }
    .show-only-in-selected {
      display: flex;
      justify-content: flex-end;
    }
  }
  .invert-icon {
    cursor: var(--cursor-pointer);
    vertical-align: middle;
    height: auto;
  }
  .checkbox-header {
    display: flex;
    justify-content: flex-end;
  }
`;

export default function PlayersList(props: {
  rows: CompleteStudent[];
  selectedPlayers: string[];
  onSelectPlayers: (list: string[]) => void;
  world: string;
  onTop?: JSX.Element;
  afterApply: (players: any[], attitudes: any[]) => void;
}) {
  const [showOptions, setShowOptions] = useState([] as any);
  const [showMarcarEmMassa, setShowMarcarEmMassa] = useState(false);
  const [extractDataDialogOpen, setExtractDataDialogOpen] = useState(false);
  const [generateQrCodesDialogOpen, setGenerateQrCodesDialogOpen] = useState(false);


  useEffect(() => {
    setShowOptions(props.selectedPlayers.length === 0);
  }, [props.selectedPlayers]);

  const changeSelectedPlayers = list => {
    setShowOptions(list.length === 0);
    props.onSelectPlayers(list);
  };

  const handleCheck = el => {
    const newSelectedPlayers = props.selectedPlayers.concat([]);
    const checkKey = newSelectedPlayers.indexOf(el.target.value);
    if (checkKey !== -1) {
      newSelectedPlayers.splice(checkKey, 1);
    } else {
      newSelectedPlayers.push(el.target.value);
    }
    changeSelectedPlayers(newSelectedPlayers);
  };

  // !
  const userType = AccessComponent.whoAmI();
  useEffect(() => {
    if (!userType) {
      return;
    }
    loadIntercomScript(props.rows.length, userType);
  }, [userType]);

  return (
    <ContainerCard
      style={{
        minHeight: "200vh"
      }}
    >
      {props.onTop}

      <TableContainer className={style}>

        <div
          className={css`
                    // only on desktop
                    @media (max-width: 960px) {
                      display: none !important;
                    }
                  `}
          style={{
            display: "flex",
            justifyContent: "flex-end"
          }}
        >
          {props.selectedPlayers.length > 0 ? (
            <button
              onClick={() => {
                changeSelectedPlayers(
                  props.rows.map(player => player.id)
                );
              }}
              className={DefaultButtonCss}
              style={{
                borderRadius: "10px",
                backgroundColor: "#FFF",
                border: "2px solid #4B3786",
                color: "#4B3786",
                padding: "5px",
                fontSize: "12px",
                cursor: "var(--cursor-pointer)r",
                fontWeight: "bold",
                opacity: 0
              }}
            >
              <strong>&nbsp;&nbsp;Marcar todos&nbsp;&nbsp;</strong>
            </button>
          ) : (
            <button
              onClick={() => {
                changeSelectedPlayers(
                  props.rows.map(player => player.id)
                );
              }}
              className={DefaultButtonCss}
              style={{
                borderRadius: "10px",
                backgroundColor: "#FFF",
                border: "2px solid #4B3786",
                color: "#4B3786",
                padding: "5px",
                fontSize: "12px",
                cursor: "var(--cursor-pointer)r",
                fontWeight: "bold"
              }}
            >
              <strong>&nbsp;&nbsp;Marcar todos&nbsp;&nbsp;</strong>
            </button>
          )}
          {Constants.isTestEnvironment() && (
            <>
              <Dialog
                open={showMarcarEmMassa}
                onClose={() => setShowMarcarEmMassa(false)}
                fullWidth
                maxWidth="lg"
              >
                <DialogContent>
                  {/* multiline input */}
                  <textarea
                    id="selecionador de alunos"
                    placeholder="Coloque aqui os dados de seus alunos"
                    style={{
                      width: "100%",
                      height: "400px",
                      border: "2px solid #4B3786",
                      borderRadius: "10px",
                      fontSize: "12px",
                      fontWeight: "bold"
                    }}
                  />
                </DialogContent>
                <DialogActions
                  style={{
                    width: "100%"
                  }}
                >
                  {/* cancel button */}
                  <button
                    className={DefaultButtonCss}
                    onClick={() => {
                      setShowMarcarEmMassa(false);
                    }}
                    style={{
                      borderRadius: "10px",
                      backgroundColor: "#FFF",
                      border: "2px solid #4B3786",
                      color: "#4B3786",
                      fontSize: "12px",
                      fontWeight: "bold",
                      marginRight: "10px"
                    }}
                  >
                    <strong>Cancelar</strong>
                  </button>
                  {/* confirm button */}
                  <button
                    className={DefaultButtonCss}
                    onClick={() => {
                      // @ts-ignore
                      const alunosList = document
                        .getElementById("selecionador de alunos")
                        // @ts-ignore
                        ?.value.split("\n")
                        .filter(Boolean);
                      const alunosIdsList = [];

                      for (const aluno of props.rows) {
                        const stringzada = JSON.stringify(aluno);

                        for (const palavra of alunosList) {
                          if (stringzada.includes(palavra)) {
                            alunosIdsList.push(aluno);
                            // remove from alunosList
                            const index = alunosList.indexOf(palavra);
                            alunosList.splice(index, 1);
                            break;
                          }
                        }
                      }
                      changeSelectedPlayers(
                        alunosIdsList.map(aluno => aluno.id)
                      );
                      if (alunosList.length > 0) {
                        alert(
                          "Não foi possível encontrar os alunos selecionados! " +
                          alunosList.join(", ")
                        );
                      } else {
                        alert("Alunos selecionados com sucesso!");
                      }
                      setShowMarcarEmMassa(false);
                    }}
                    style={{
                      borderRadius: "10px",
                      backgroundColor: "#4B3786",
                      border: "2px solid #4B3786",
                      color: "#FFF",
                      fontSize: "12px",
                      fontWeight: "bold",
                      marginRight: "10px"
                    }}
                  >
                    <strong>Confirmar</strong>
                  </button>
                </DialogActions>
              </Dialog>
              <button
                className={DefaultButtonCss}
                onClick={() => {
                  setShowMarcarEmMassa(true);
                }}
                style={{
                  borderRadius: "10px",
                  backgroundColor: "#FFF",
                  border: "2px solid #4B3786",
                  color: "#4B3786",
                  fontSize: "12px",
                  fontWeight: "bold"
                }}
              >
                <strong>Marcar em massa</strong>
              </button>
              <button
                className={DefaultButtonCss}
                onClick={() => {
                  const semQrCode = props.rows.filter(
                    player => !player.accessHash
                  );
                  changeSelectedPlayers(
                    semQrCode.map(player => player.id)
                  );
                }}
                style={{
                  borderRadius: "10px",
                  backgroundColor: "#FFF",
                  border: "2px solid #4B3786",
                  color: "#4B3786",
                  fontSize: "12px",
                  fontWeight: "bold"
                }}
              >
                <strong>Marcar os sem qrcode</strong>
              </button>

              <button
                className={DefaultButtonCss}
                onClick={() => {
                  const semTurma = props.rows.filter(
                    player => player.class.length === 0
                  );
                  changeSelectedPlayers(
                    semTurma.map(player => player.id)
                  );
                }}
                style={{
                  borderRadius: "10px",
                  backgroundColor: "#FFF",
                  border: "2px solid #4B3786",
                  color: "#4B3786",
                  fontSize: "12px",
                  fontWeight: "bold"
                }}
              >
                <strong>Marcar os sem turma</strong>
              </button>

              <button
                className={DefaultButtonCss}
                onClick={() => {
                  const semTurma = props.rows.filter(
                    player => player.games.length === 0
                  );
                  changeSelectedPlayers(
                    semTurma.map(player => player.id)
                  );
                }}
                style={{
                  borderRadius: "10px",
                  backgroundColor: "#FFF",
                  border: "2px solid #4B3786",
                  color: "#4B3786",
                  fontSize: "12px",
                  fontWeight: "bold"
                }}
              >
                <strong>Marcar os sem game</strong>
              </button>


              <button
                className={DefaultButtonCss}
                onClick={() => {
                  setExtractDataDialogOpen(!extractDataDialogOpen);
                }}
                style={{
                  borderRadius: "10px",
                  backgroundColor: "#FFF",
                  border: "2px solid #4B3786",
                  color: "#4B3786",
                  fontSize: "12px",
                  fontWeight: "bold"
                }}
              >
                <strong>Extrair dados</strong>
              </button>

              <button
                className={DefaultButtonCss}
                onClick={() => {
                  setGenerateQrCodesDialogOpen(!generateQrCodesDialogOpen);
                }}
                style={{
                  borderRadius: "10px",
                  backgroundColor: "#FFF",
                  border: "2px solid #4B3786",
                  color: "#4B3786",
                  fontSize: "12px",
                  fontWeight: "bold"
                }}
              >
                <strong>Gerar QR Codes sem nome </strong>
              </button>
            </>
          )}

          {/* {props.selectedPlayers.length > 0 && (
                  <>
                    <button
                      onClick={() => {
                        changeSelectedPlayers([]);
                      }}
                      style={{
                        borderRadius: '10px',
                        backgroundColor: '#FFF',
                        border: '2px solid #4B3786',
                        color: '#4B3786',
                        fontSize: '12px',
                        fontWeight: 'bold',
                      }}
                    >
                      <strong>Desmarcar tudo</strong>
                    </button>
                  </>
                )} */}
        </div>


        <table
          className={!showOptions ? "hide-options" : ""}
          style={{
            width: "100%"
          }}
        >
          <tbody>
            <tr>
              <td>
                {props.selectedPlayers.length > 0 ? (
                  <div>
                    <input
                      type="checkbox"
                      checked={
                        props.selectedPlayers.length === props.rows.length
                      }
                      onChange={() => changeSelectedPlayers([])}
                      style={{
                        marginLeft: "10px",
                        width: "20px",
                        height: "20px"
                      }}
                    />
                  </div>
                ) : (
                  <div>
                    <input
                      type="checkbox"
                      checked={
                        props.selectedPlayers.length === props.rows.length
                      }
                      onChange={() =>
                        changeSelectedPlayers(
                          props.rows.map(player => player.id)
                        )
                      }
                      style={{
                        marginLeft: "10px",
                        width: "20px",
                        height: "20px"
                      }}
                    />
                  </div>
                )}
              </td>

              <td
                className={css`
                  @media (max-width: 960px) {
                    display: none !important;
                  }
                `}
              >
                <strong>Nível</strong>
              </td>
              <td>
                <strong>Nome</strong>
              </td>
              <td />
            </tr>
            
          {(props.rows || []).length === 0 && (
            <TableRow>
              <td colSpan={999} align="center">
                Nenhum jogador encontrado para sua busca.
              </td>
            </TableRow>
          )}
          {(props.rows || [])?.map(row => (
            <PlayersListItem
              key={row.id}
              player={row}
              world={props.world}
              onSelect={handleCheck}
              isSelected={props.selectedPlayers.indexOf(row.id) !== -1}
              afterApply={props.afterApply}
            />
          ))}
          </tbody>

        </table>
      </TableContainer>
      <DataExtractor
          fileName={props.world}
          extractDataDialogOpen={extractDataDialogOpen}
          setExtractDataDialogOpen={setExtractDataDialogOpen}
          datas={props.rows}
        />

      <GenerateQrCodesDialog
          generateQrCodesDialogOpen={generateQrCodesDialogOpen}
          setGenerateQrCodesDialogOpen={setGenerateQrCodesDialogOpen}
          rows={props.rows}
        />
    </ContainerCard>

  );
}

function GenerateQrCodesDialog(props: {
  generateQrCodesDialogOpen: boolean;
  setGenerateQrCodesDialogOpen: (open: boolean) => void;
  rows: CompleteStudent[];
}) {
  const [amount, setAmount] = useState(0);
  return <Dialog
    open={props.generateQrCodesDialogOpen}
    onClose={() => props.setGenerateQrCodesDialogOpen(false)}
  >
    <DialogContent>
      <input
        type="number"
        value={amount}
        onChange={e => setAmount(parseInt(e.target.value) ?? 0)}

      />
    </DialogContent>

    <DialogActions>
      <button
        onClick={() => props.setGenerateQrCodesDialogOpen(false)}
      >
        <strong>Cancelar</strong>
      </button>

      <button
        onClick={async () => {
          const client = new Client();
          client.generateBlankQrCodes(amount);
          alert("QR Codes serão gerados em no máximo 10 minutos, confira no histórico");
          return props.setGenerateQrCodesDialogOpen(false);
        }}
      >

        <strong>Gerar</strong>
      </button>
    </DialogActions>
  </Dialog>
}


function DataExtractor<T>(props: {
  extractDataDialogOpen: boolean;
  setExtractDataDialogOpen;
  datas: T[];
  fileName?: string;
}) {
  const {
    extractDataDialogOpen,
    setExtractDataDialogOpen,
    datas,
    fileName
  } = props;
  const [selectedKeys, setSelectedKeys] = useState([] as string[]);
  return (
    <Dialog
      open={extractDataDialogOpen}
      onClose={() => setExtractDataDialogOpen(false)}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          flexWrap: "wrap"
        }}
      >
        {Boolean(datas[0]) &&
          Object.keys(datas[0]).map(key => (
            <button
              key={key}
              style={{
                margin: "10px"
              }}
              onClick={() => {
                if (selectedKeys.indexOf(key) === -1) {
                  setSelectedKeys(selectedKeys.concat([key]));
                } else {
                  setSelectedKeys(selectedKeys.filter(k => k !== key));
                }
              }}
            >
              <strong>{key}</strong>
              <input
                type="checkbox"
                checked={selectedKeys.indexOf(key) !== -1}
              />
            </button>
          ))}
      </div>

      <button
        onClick={() => {
          const data = datas.map(d => {
            const newD = {} as any;
            for (const key of selectedKeys) {
              newD[key] = d[key];
            }
            return newD;
          });

          // @ts-ignore
          const XLSX = require("xlsx");

          const worksheet = XLSX.utils.json_to_sheet(data);
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

          // Gere o arquivo Excel
          const xlsData = XLSX.write(workbook, {
            bookType: "xlsx",
            type: "array"
          });

          // Crie o blob e o link de download
          const blob = new Blob([xlsData], {
            type: "application/octet-stream"
          });
          const url = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = (fileName ?? "data") + ".xlsx";
          document.body.appendChild(a);
          a.click();
        }}
        style={{
          margin: "10px"
        }}
      >
        <strong>Baixar</strong>
      </button>
    </Dialog>
  );
}

function TableRankingTurmas(props: { rows: CompleteStudent[] }) {
  let allturmas = [] as {
    name: string;
    id: string;
    totalPoints: Record<string, number>;
  }[];
  for (const aluno of props.rows) {
    for (const turma of aluno.class) {
      if (!allturmas.find(t => t.id === turma.id)) {
        allturmas.push({
          name: turma.name,
          id: turma.id,
          totalPoints: {}
        });
      }
    }
  }

  for (const aluno of props.rows) {
    for (const turma of aluno.class) {
      const turmaObj = allturmas.find(t => t.id === turma.id);
      if (turmaObj) {
        for (const point of aluno.games.map(game => game.points)) {
          for (const key in point) {
            if (turmaObj.totalPoints[key]) {
              turmaObj.totalPoints[key] += point[key];
            } else {
              turmaObj.totalPoints[key] = point[key];
            }
          }
        }
      }
    }
  }

  allturmas = allturmas.sort((a, b) => {
    const aTotal = Object.keys(a.totalPoints).reduce(
      (acc, key) => acc + a.totalPoints[key],
      0
    );
    const bTotal = Object.keys(b.totalPoints).reduce(
      (acc, key) => acc + b.totalPoints[key],
      0
    );
    return bTotal - aTotal;
  });

  return (
    <table>
      <thead>
        <tr>
          <td colSpan={4}>
            <h2>Turmas</h2>
          </td>
          {allturmas[0]?.totalPoints &&
            Object.keys(allturmas[0].totalPoints).map(key => (
              <td
                key={key}
              >
                <strong>{key}</strong>
              </td>
            ))}
          {allturmas[0]?.totalPoints && (
            <td>
              <strong>Total</strong>
            </td>
          )}
        </tr>
      </thead>
      <tbody>
        {allturmas.map(turma => (
          <tr
            key={turma.id}
          >
            <td colSpan={4}>
              <h2>{turma.name}</h2>
            </td>

            {Object.keys(turma.totalPoints).map(key => (
              <td
                key={key}
              >{turma.totalPoints[key]}</td>
            ))}

            <td>
                {Object.keys(turma.totalPoints).reduce(
                  (acc, key) => acc + turma.totalPoints[key],
                  0
                )}
              </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export async function loadIntercomScript(userAmount, userType) {
  Settings.loadSettings().then(async settings => {
    const data = settings;
    const unparsedJWT = localStorage.getItem("TOKEN_LOGIN");
    const jwt = JSON.parse(atob(unparsedJWT?.split(".")[1]));
    const email = jwt.email;
    const userId = jwt.userId;
    const invoices = (data.accountsDetails ?? [])[0]?.invoices ?? [];
    const hash = data.intercomHash;

    const getStatus = (invoices: any[]) => {
      let status = "Pago";

      for (const invoice of invoices) {
        if (invoice.status === "open") {
          status = "Pendente";
        }
      }

      for (const invoice of invoices) {
        if (
          invoice.status === "open" &&
          new Date(invoice.due_date * 1000).getTime() < Date.now()
        ) {
          status = "Vencida";
        }
      }

      for (const invoice of invoices) {
        const fiveDaysLater = new Date(invoice.due_date * 1000);
        fiveDaysLater.setDate(fiveDaysLater.getDate() + 5);
        if (invoice.status === "open" && fiveDaysLater.getTime() < Date.now()) {
          status = "Inadimplente";
        }
      }
      return status;
    };

    const status = getStatus(invoices);

    const diasAteVencimento = invoices[0]
      ? Math.floor(
        (new Date(invoices[0].due_date * 1000).getTime() - Date.now()) /
        (1000 * 60 * 60 * 24)
      )
      : 0;

    if (!data) {
      alert("Erro ao carregar dados");
      return;
    }
    if (!userType) {
      alert("Erro ao carregar usertype");
      return;
    }
    if (!email) {
      alert("Erro ao carregar email");
      return;
    }
    const result = await Settings.getData("disabled");
    let scriptText = "";
    if (email && userId && data?.schoolName && data.username != 'undefined' && data.username != null) {
      scriptText = `
      window.intercomSettings = {
      api_base: "https://api-iam.intercom.io",
      app_id: "kbmjku7r",
      ${hash && hash.length > 0 ? `user_hash: "${hash}",` : ""}
      name: "${data.username}",
      phone: "${data.userData.phone != '+55' ? data.userData.phone : ''}",
      "WhatsApp number": "${data.userData.phone != '+55' ? data.userData.phone : ''}",
      user_id: "${userId}",
      email: "${email}",
      company: {
        company_id: "${data.schoolName}",
        name: "${data.schoolName}",
        ${userAmount ? `"Students registered": ${userAmount},` : ""}
        "Company Blocked": ${result},
        "Status fatura": "${status}",
        "Dias ate vencimento fatura": ${diasAteVencimento},
      },
      "Status fatura": "${status}",
      "Dias ate vencimento fatura": ${diasAteVencimento},
      "Tag do usuario": "${userType}",
      };
    `;
    }
    const scriptText2 = `
    // We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/kbmjku7r'
    (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/kbmjku7r';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
    `;
    const script = document.createElement("script");
    script.text = scriptText;
    console.log("script", scriptText);
    document.body.appendChild(script);
    const script2 = document.createElement("script");
    script2.text = scriptText2;
    document.body.appendChild(script2);
  });

  return () => { };
}
