import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function TabPanel(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          {props.items.map((item, key) => (
            <Tab
              label={item.title}
              id={`simple-tab-${key}`}
              aria-controls={`simple-tabpanel-${key}`}
            />
          ))}
        </Tabs>
      </AppBar>
      {props.items.map((item, key) => (
        <div
          role="tabpanel"
          hidden={value !== key}
          id={`simple-tabpanel-${key}`}
          aria-labelledby={`simple-tab-${key}`}
        >
          {value === key && item.children}
        </div>
      ))}
    </div>
  );
}
