 
import jwtDecode from 'jwt-decode';

import Client from '../client';
import Settings from '../settings';

const STORAGE_WORD = 'TOKEN_LOGIN';

export default class AuthService {
  static async isAuthenticated() {
    const token = localStorage.getItem(STORAGE_WORD);
    if (!token) {
      return;
    }
    const tokenDecode = (await jwtDecode(token)) as any;

    const dateNow = new Date();

    if (tokenDecode.expires < dateNow.getTime()) {
      this.logout();
      return false;
    }
    return true;
  }

  static async setToken(token) {
    localStorage.setItem(STORAGE_WORD, token);
    await Settings.loadSettings();
    // stop js execution

    return {
      error: false,
      token: token,
    };
  }

  static async changeSessionOnAccount({ token, accountId, world }) {
    const client = new Client();
    const response = await client.changeSessionOnAccount({
      token,
      accountId,
      world,
    });
    if (!response.error) {
      await this.setToken(response.token);
    }
    return response;
  }

  static async login({ username, password, preferrAccount }) {
    const client = new Client();
    const response = await client.auth({ username, password, preferrAccount });
    if (!response.error) {
      await this.setToken(response.token);
    }
    return response;
  }

  static isLogged() {
    try {
      return localStorage.getItem(STORAGE_WORD);
    } catch (_e) {
      return null;
    }
  }

  static async logout() {
    Settings.unloadSettings();
    localStorage.removeItem(STORAGE_WORD);
  }

  static async sendNewPassword(data, uid, token) {
    const client = new Client();
    return client.sendNewPassword(data, uid, token);
  }
}
