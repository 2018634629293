import React from "react";

import { css } from "emotion";

import { useToast } from '@/context/useToast';

import Client from "../../../services/client";
import QRCodeIcon from "../../atoms/qr-code-icon";
import SnackAlert from "../../atoms/snack-alert";
import PlayerQrCodeDialog from "../../molecules/player-qr-code-dialog/index";
export const DefaultButtonRoundCss = css`
  border: 0;
  cursor: var(--cursor-pointer);
  background-color: transparent;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  transition: box-shadow 0.3s ease;
  border: 0;
  cursor: var(--cursor-pointer);
  transition: 0.1s ease;
  &:hover {
    background-color: #ecedef;
  }
  &:active {
    background-color: #d1d3d4;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
`;

const client = new Client();

function AttachPlayerQrCode(props) {
  const [open, setOpen] = React.useState(false);
  const [code, setCode] = React.useState(false);
  const [alert, setAlert] = React.useState(false as string | false);

  const justSaveHash = async newHash => {
    await client.postCreateHash(props.player.id, newHash);
  };

  const { showToast } = useToast()

  const saveHash = async newHash => {
    await client.postCreateHash(props.player.id, newHash);
    showToast("QRCode cadastrado com sucesso.")
    setOpen(false);
    setAlert(false);
  };


  const afterRead = async data => {
    try {
      const isInUse = await client.getPlayerByHash(data);
      if (isInUse && isInUse.id !== props.player.id) {
        setAlert(
          `Essa ação é irreversivel. Se você continuar, o ${isInUse.firstName} ${isInUse.surname} perderá instantaneamente acesso a todas as plataformas gamefik através deste QR Code.`
        );
        setCode(data);
        return;
      }
    } catch (error) {
      console.error(error);
    }
    saveHash(data);
  };

  return (
    <div>
      <button
        className={DefaultButtonRoundCss}
        title={code || props.exists ? "QRCode já vinculado" : "Vincular QRCode"}
        onClick={() => setOpen(true)}
      >
        <QRCodeIcon exists={code || props.exists} />
      </button>
      {Boolean(open) && (
        <PlayerQrCodeDialog
          open={Boolean(open)}
          onClose={() => {
            setOpen(false);
          }}
          player={props.player}
          onFinish={saveHash}
          saveHash={justSaveHash}
        />
      )}
    </div>
  );
}

export default AttachPlayerQrCode;
