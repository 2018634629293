import { css } from "emotion";
import { Key } from "mdi-material-ui";

import { AttachPlayerAttitude } from "../../organisms/attach-player-attitude";
import AttachPlayerQrCode, {
  DefaultButtonRoundCss
} from "../../organisms/attach-player-qr-code";
import { EditPlayerOption } from "../../organisms/edit-player-option";
import { HistoryView } from "../../organisms/history-view";
import { DefaultButtonCss } from "../../organisms/players-actions";
import { Colors } from "../../themes/color";
import { Constants } from "../advanced-settings-form";
// import { Colors } from "../../themes/color";

export const hideInMobile = css`
  @media (max-width: 960px) {
    display: none !important;
  }
`;

export const hideInDesktop = css`
  @media (min-width: 960px) {
    display: none !important;
  }
`;

export default function PlayerListOptions({
  player,
  world,
  className,
  style,
  afterApply
}: {
  player: any;
  world: any;
  className?: any;
  style?: any;
  afterApply: any;
}) {
  return (
    <div
      className={className}
      onClick={e => {
        e.stopPropagation();
      }}
      style={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        ...style
      }}
    >
      {Boolean(player.accessHash) && (
        <button
          className={DefaultButtonCss}
          title="Acessar a conta"
          style={{
            fontFamily: "Fredoka",
            fontSize: "14px",
            color: "#4F56D3",
            fontWeight: 500,
            backgroundColor: Colors.green,
            borderRadius: "10px",
            padding: "5px 10px",
          }}
          onClick={e => {
            window.location.href ="/spot" + "?accessHash=" + player.accessHash + "&world=" + world;
            // !process.env.NEXT_PUBLIC_SPOT.includes("localhost")
            //   ? "https://hmg-spot.gamefik.com"
            //   : process.env.NEXT_PUBLIC_SPOT ??
            //     "https://webspot-git-dev-gamefik-br.vercel.app") +
            //   "/spot/" +
            //   world +
            //   "?accessHash=" +
            //   player.accessHash
          }}
        >
          ACESSAR
        </button>
      )}
      <AttachPlayerQrCode player={player} exists={Boolean(player.accessHash)} />
      <HistoryView playerId={player.id} />
      <EditPlayerOption playerId={player.id} />

      <AttachPlayerAttitude
        open={false}
        icon
        players={[player]}
        changeOpen={function () { }}
        afterApply={afterApply}
      />

      {/* <div className={hideInDesktop}>
        <IconButton
          title="Mais opções"
          onClick={event => setAnchorEl(event.currentTarget)}
        >
          <DotsVertical fontsize="small" />
        </IconButton>
        <Menu
          id="simple-menu"
          elevation={0}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          <MenuItem>
            <HistoryView playerId={player.id} complete />
          </MenuItem>
          <MenuItem>
            <AttachPlayerReward player={player} complete />
          </MenuItem>
          <MenuItem>
            <AttachPlayerQrCode
              player={player}
              exists={Boolean(player.accessHash)}
              complete
            />
          </MenuItem>
          <MenuItem>
            <EditPlayerOption playerId={player.id} complete />
          </MenuItem>
        </Menu>
      </div> */}
    </div>
  );
}
