import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function MultipliableDialog(props) {
  const [amount, setAmount] = React.useState(1);

  const onFinish = () => {
    props.onFinish({
      ...props.attitude,
      amount: parseInt(amount, 10),
    });
  };

  return (
    <Dialog
      style={{
        zIndex: 99999,
      }}
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Qual a quantidade?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Quantas vezes essa atitude será aplicada?
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="amount"
          label="Quantidade"
          type="number"
          onChange={element => setAmount(element.target.value)}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => {
          props.onClose(props.attitude);
        }} color="primary" style={{
          width: '100%',
        }}>
          Cancelar
        </Button>
        <Button onClick={onFinish} color="primary" variant="contained" style={{
          width: '100%',
        }}>
          {'Adicionar ' + amount}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
