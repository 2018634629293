import React, { createContext, useContext, useState, ReactNode } from 'react';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface ToastContextProps {
  showToast: (message: string, color?: string) => void;
}

const ToastContext = createContext<ToastContextProps>(
  {
    showToast: () => { },
  }
);

export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};

export function ToastProvider({ children }) {
  const showToast = (message: string, color = "white") => {
    if (typeof window !== 'undefined') {
      const isMobile =
        typeof window !== 'undefined' && window.innerWidth <= 600;
      if (isMobile) {
        // toast(message, {
        //   style: { backgroundColor: color },
        //   position: 'top-left',
        // });
      } else {
        toast(message, {
          style: { backgroundColor: color },
        });
      }
    }
  };

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      <ToastContainer position="bottom-left" />
    </ToastContext.Provider>
  );
};
