import CreateIcon from "@material-ui/icons/Create";

import { DefaultButtonRoundCss } from "../attach-player-qr-code";

export function EditPlayerOption(props) {
  return (
    <button
      className={DefaultButtonRoundCss}
      title="Editar jogador"
      onClick={e => {
        e.stopPropagation();
        window.location.href = `/players/edit/${props.playerId}`;
      }}
    >
      <CreateIcon fontSize="small" />
    </button>
  );
}
