import React, { createContext, useContext, ReactNode, useEffect } from "react";

import useLocalStorage from "../internal-libs/uselocalstorage";
import Client from "../services/client";

type ContextValue = {
  data: {
    amountOfMissoesLeftToCheck: number;
  };
  updateData: (newData: ContextValue["data"]) => void;
  updateAmountOfMissoesLeftToCheck: () => void;
};

const GlobalContext = createContext<ContextValue | undefined>(undefined);

type GlobalProviderProps = {
  children: ReactNode;
  initialData?: ContextValue["data"];
};

export const GlobalProvider: React.FC<GlobalProviderProps> = ({
  children,
  initialData = { amountOfMissoesLeftToCheck: 0 }
}) => {
  const [data, setData] = useLocalStorage("Global_Data", initialData);

  const updateData = React.useCallback(
    (
      newData: ContextValue["data"] | ((data: ContextValue["data"]) => void)
    ) => {
      setData(newData as any);
    },
    []
  );

  const updateAmountOfMissoesLeftToCheck = React.useCallback(() => {
    Client.authenticatedRequest({
      method: "GET",
      path: "missoes2/comprovantes/number-of-pendentes"
    }).then(response => {
      updateData(data => {
        return {
          ...data,
          amountOfMissoesLeftToCheck: response.data.data
        };
      });
    });
  }, []);

  useEffect(() => {
    updateAmountOfMissoesLeftToCheck();
  }, []);

  const value = React.useMemo(
    () => ({
      data,
      updateData,
      updateAmountOfMissoesLeftToCheck
    }),
    [data, updateData]
  );

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
};

export const useGlobalContext = () => {
  const context = useContext(GlobalContext);
  if (context === undefined) {
    throw new Error("useBoundedContext must be used within a BoundedProvider");
  }
  return context;
};
