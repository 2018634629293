import React from "react";

import HistoryIcon from "@material-ui/icons/History";

import HistoryDialog from '../../molecules/history-dialog';
import { DefaultButtonRoundCss } from "../attach-player-qr-code";

export function HistoryView({ playerId }: { playerId: string }) {
  const [open, setOpen] = React.useState(false);
  return (
    <div>
      <button
        className={DefaultButtonRoundCss}
        title="Histórico do jogador"
        onClick={e => {
          e.stopPropagation();
          return setOpen(true);
        }}
      >
        <HistoryIcon fontSize="small" />
      </button>
      {open && (
        <HistoryDialog
          open={Boolean(open)}
          playerId={playerId}
          onClose={() => setOpen(false)}
        />
      )}
    </div>
  );
}
