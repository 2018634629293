import { css } from "emotion";

import Settings from "../../../services/settings";
import PlayerListOptions, {
  hideInDesktop
, hideInMobile } from "../../molecules/player-list-options";


const getGameText = gameData => {
  if (!gameData.points) {
    return null;
  }
  const pointsArray = [];
  Object.keys(gameData.points).map(key =>
    pointsArray.push(`${key}: ${gameData.points[key]}`)
  );
  return pointsArray.join(" | ");
};
const getRewardsToCollectFromMissions = gameData => {
  const rewards = gameData.rewardsToCollectFromMissions;
  if (!rewards) {
    return null;
  }

  const rewardsFromMissions2 = { ...gameData?.totalRewardsToCollectFromMissions2 };

  if (rewardsFromMissions2) {
    Object.keys(rewardsFromMissions2).map(key => {
      rewards[key] = (rewards[key] || 0) + (rewardsFromMissions2[key] || 0);
    });
  }


  const pointsArray = [];
  Object.keys(rewards).map(key => pointsArray.push(`${key}: ${rewards[key]}`));
  return (
    <small>
      <strong>Missões por coletar:</strong> <em>{pointsArray.join(" | ")}</em>
    </small>
  );
};

const getRewardsToCollectFromSald = gameData => {
  const rewards = gameData.rewardsTocollectfromSald;
  if (!rewards) {
    return null;
  }
  const pointsArray = [];
  Object.keys(rewards).map(key => pointsArray.push(`${key}: ${rewards[key]}`));
  return (
    <small>
      <strong>Atitudes por coletar:</strong> <em>{pointsArray.join(" | ")}</em>
    </small>
  );
};

export default function PlayersListItem({
  player,
  onSelect,
  isSelected,
  world,
  afterApply
}) {
  const level = player.games[0] ? player.games[0].level : undefined;

  return (
    <>
      <tr
        style={{
          borderBottom: "1px solid #f1f2f4",
          padding: "10px"
        }}
        key={player.id}
        onClick={e => {
          e.target = {
            ...e.target,
            value: player.id
          } as any;
          onSelect && onSelect(e);
        }}
        className={css`
          cursor: var(--cursor-pointer);
          &:hover {
            background-color: #f3f4f6;
          }
        `}
      >
        <td align="right">
          <input
            type="checkbox"
            value={player.id}
            onClick={onSelect}
            onChange={() => { }}
            checked={isSelected}
            style={{
              transform: "scale(1.5)",
              cursor: "var(--cursor-pointer)r"
            }}
          />
        </td>
        <td
          className={css`
            @media (max-width: 960px) {
              display: none !important;
            }
          `}
        >
          {level && (
            <div>
              <img
                src={
                  "https://gamefik-proxy-28f7edd7cede.herokuapp.com/webp?q=" +
                  level.source
                }
                className="icon"
                style={{
                  width: "40px",
                  transform: "scale(2)"
                }}
              />
              <br />
              <div
                style={{
                  width: "40px"
                }}
              >
                <center style={{}}>{level.order}</center>
              </div>
            </div>
          )}
        </td>

        <td style={{
          width: "100%"
        }}>
          <strong>{` ${player.firstName} ${player.surname}`}</strong>
          <br />
       
          <small>
          <strong>
            Game: 
          </strong>
          {
            player.games.length > 0 ?
            " " + player.games.map(e => e.name) : <span
            style={{
              color: "red"
            }}>
              {
                " SEM JOGOS"
              }
            </span>
          }
          </small>
          <br />
  
          <small>
          <strong>
            Turma: 
          </strong>
          {
            player.class.length > 0 ?
            " " + player.class.map(e => e.name) : <span style={{
              color: "red"
            }}>
              {
                " SEM TURMAS"
              }
            </span>
          }
          </small>
          <br />


          <div>
            <small>
              <strong>Saldo: </strong>
              <em>{player.games && player.games.map(getGameText)}</em>
            </small>
          </div>

     

          <div style={{}}>
            {player.games && player.games.length > 0 && (
              <>
                <>
                  <em>
                    {getRewardsToCollectFromMissions(player.games[0])}
                  </em>
                  <br />
                </>
                <em>{getRewardsToCollectFromSald(player.games[0])}</em>
              </>
            )}
            <br />
          </div>
          <PlayerListOptions
              player={player}
              key={player.id}
              world={world}
              className={hideInDesktop}
              style={{
                justifyContent: "flex-start",
                width: "100%"
              }}
              afterApply={afterApply}
            />
        </td>

        <td align="right">
          <PlayerListOptions
              player={player}
              key={player.id}
              world={world}
              className={hideInMobile}
              afterApply={afterApply}
            />
        </td>
      </tr>
      {/* {open && (
        <AttachPlayerAttitude
          players={[player]}
          changeOpen={changeOpen}
          open={open}
        />
      )} */}
    </>
  );
}

export function DefaultLevelPlayerImage(props: {
  player: CompleteStudent;
  style: React.CSSProperties;
}) {
  const { player, style } = props;

  const getLevelIcon = (game, level) => {
    const levels = Settings.getSettings().levels;
    let source: string, name: string, order: any;
    for (const obj of levels[game]) {
      if (obj.id == level) {
        source = obj.source;
        name = obj.name;
        order = obj.order;
      }
    }

    return { source, name, order };
  };

  for (const game of player.games) {
    const levelInfo = getLevelIcon(game.id, game.level.id);
    (game.level as any).source = levelInfo.source;
    (game.level as any).name = levelInfo.name;
    (game.level as any).order = levelInfo.order;
  }

  return (
    <img
      src={
        "https://gamefik-proxy-28f7edd7cede.herokuapp.com/webp?q=" +
        (player.games?.[0]?.level as any).source
      }
      style={style}
    />
  );
}
