import { createContext, useContext } from 'react';

import useLocalStorage from '../internal-libs/uselocalstorage';
import Client from '../services/client';

const client = new Client();

const UserContextAplication = createContext(undefined);

export const ACCESS_LEVELS = {
  TEACHER: 30,
  SECRETARY: 80,
  MANAGER: 100
}

const UserAplicationProvider = ({ isAuth, children }) => {
  const [userAccessLevel, setUserAccessLevel] = useLocalStorage(
    'userAccessLevel',
    0
  );

  async function handleAccessLevel() {
    const userLevelAccess = await client.getUserLevelAccess();
    setUserAccessLevel(userLevelAccess);
    return userLevelAccess;
  }

  const context = {
    userAccessLevel,
    handleAccessLevel,
    loadedAccessLevel: userAccessLevel !== 0,
  };

  return (
    <>
      <UserContextAplication.Provider value={context}>
        {children}
      </UserContextAplication.Provider>
    </>
  );
};

export { UserAplicationProvider, UserContextAplication };

export function useUser() {
  return useContext(UserContextAplication) as {
    userAccessLevel: number;
    handleAccessLevel: () => Promise<number>;
    loadedAccessLevel: boolean;
  };
}
