import React from 'react'

import Image from 'next/image';

import { Button } from '@material-ui/core'

import AuthService from '@/services/auth';

import Logo from "../../public/assets/img/logo.png"

interface ErrorBoundaryState {
  hasError: boolean;
  error?: Error;
}

class ErrorBoundary extends React.Component<{ children: React.ReactNode }, ErrorBoundaryState> {
  constructor(props) {
    super(props)
    this.state = { 
      hasError: false,
      error: undefined
    }
  }

  logout() {
    AuthService.logout();
    window.location.href = "/login";
  }

  downloadErrorLog = () => {
    const errorMessage = this.state.error?.message || "Erro desconhecido";
    const errorStack = this.state.error?.stack || "";
    const timestamp = new Date().toISOString();
    
    const errorContent = `
Data e Hora: ${timestamp}
Mensagem de Erro: ${errorMessage}
Stack Trace: 
${errorStack}
`;
    
    const blob = new Blob([errorContent], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    
    link.href = url;
    link.download = `erro-aplicacao-${new Date().getTime()}.txt`;
    document.body.appendChild(link);
    link.click();
    
    setTimeout(() => {
      URL.revokeObjectURL(url);
      document.body.removeChild(link);
    }, 100);
  }

  static getDerivedStateFromError(error: Error) {
    return { 
      hasError: true,
      error 
    }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    // You could log the error to an error reporting service here
    console.error("Error caught by boundary:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
            gap: "24px",
            fontSize: "20px",
            padding: "0 20px"
          }}>
          <Image src={Logo} alt="logo" />
          
          <div style={{
            backgroundColor: "#fff",
            border: "1px solid #e0e0e0",
            borderRadius: "8px",
            padding: "20px",
            boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
            maxWidth: "800px",
            width: "100%",
            marginBottom: "20px"
          }}>
            <h2 style={{ color: "#d32f2f", marginTop: 0 }}>Erro na Aplicação</h2>
            <p>
              Algum erro aconteceu com a nossa aplicação:
            </p>
            <div style={{
              backgroundColor: "#f8f8f8",
              padding: "16px",
              borderRadius: "4px",
              overflow: "auto",
              fontFamily: "monospace",
              fontSize: "14px",
              color: "#d32f2f"
            }}>
              {this.state.error?.message || "Erro desconhecido"}
            </div>
            
            <div style={{ 
              display: "flex", 
              justifyContent: "flex-end", 
              marginTop: "10px" 
            }}>
              <Button
                onClick={this.downloadErrorLog}
                style={{
                  backgroundColor: "#f5f5f5",
                  color: "#333",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  padding: "6px 12px",
                  fontSize: "14px",
                  fontWeight: "500",
                  textTransform: "none"
                }}
              >
                Baixar informações do erro
              </Button>
            </div>
            
            <p style={{ marginTop: "16px" }}>
              Por favor <a
                href='https://api.whatsapp.com/message/RZG3CHJWLLJ7K1?autoload=1&app_absent=0'
                target='_blank'
                style={{ color: "#646AE2" }}
              >
                contate o suporte
              </a> faça o download do erro o envie para o atendente.
            </p>
          </div>

          <Button
            onClick={() => this.logout()}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "16px",
              backgroundColor: "#54fab8",
              borderRadius: "4px",
              fontWeight: "700",
              color: "#646AE2",
              textTransform: "uppercase",
              padding: "10px 20px"
            }}
          >
            Voltar a pagina de login
          </Button>
        </div>
      )
    }
    return this.props.children
  }
}

export default ErrorBoundary