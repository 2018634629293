import React from "react";

import { useToast } from '@/context/useToast';

import AttitudesDialog from "../../molecules/attitudes-dialog";
import { Colors } from "../../themes/color";
import { DefaultButtonRoundCss } from "../attach-player-qr-code";


export function AttachPlayerAttitude(props: {
  open: any;
  icon: any;
  players: any[];
  changeOpen: () => any;
  afterApply: (players: any[], attitudes: any[]) => void;
}) {
  const [open, setOpen] = React.useState(props.open);

  const { showToast } = useToast()

  return (
    <div>
      {props.icon && (
        <button
          className={DefaultButtonRoundCss}
          title="Aplicar"
          onClick={() => setOpen(true)}
          style={{
            background: Colors.purple,
            marginLeft: "10px",
            width: "50px",
            height: "50px",
            aspectRatio: "1/1"
          }}
        >
          <img
            src="/atitude.png"
            alt="atitude"
            style={{ width: "100%", scale: "0.6" }}
          />
          {/* <ThumbsUpDownIcon
            fontSize="medium"
            style={{
              color: Colors.green
            }}
          /> */}
        </button>
      )}
      {open && (
        <AttitudesDialog
          open={Boolean(open)}
          players={props.players}
          afterApply={props.afterApply}
          onClose={() => {
            setOpen(false);
            props.changeOpen ? props.changeOpen() : null;
          }}
          onError={message => {
            showToast(message as any);
            setOpen(false);
            props.changeOpen ? props.changeOpen() : null;
          }}
        />
      )}
    </div>
  );
}
