import { css } from 'emotion';
import React from 'react';
import Lottie from 'react-lottie';
import rocket from './animation.json';

export default function RocketLoading() {
  return (
    <div
      className={css`
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        z-index: 9999999;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(255, 255, 255, 0.5);
      `}
    >
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: rocket,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          },
        }}
        height={300}
        width={300}
      />
    </div>
  );
}



export function RocketLoadingSimple() {
  return (
    <Lottie
      options={{
        loop: true,
        autoplay: true,
        animationData: rocket,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      }}
      height={300}
      width={300}
    />
  );
}
