import { useState } from 'react';

import dynamic from 'next/dynamic';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import download from 'downloadjs';
import { css } from 'emotion';
import html2canvas from 'html2canvas';
import { QRCode } from 'react-qr-svg';

import AlertDialog from '../../atoms/alert-dialog';
import QrCardLayout from '../../atoms/qr-card-layout';

const QrCodeReader = dynamic(() => import('../../atoms/qr-code-reader'), {
  ssr: false,
});

function PlayerQrCodeDialog(props) {
  const [code, setCode] = useState('');
  const [open, setOpen] = useState(false);
  const [stopCam, setStopCam] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [isActiveCamera, setIsActiveCamera] = useState(true);
  const [isQrCardActive, setIsQrCardActive] = useState(false);

  const onClose = () => {
    props.onClose();
  };

  const afterRead = data => {
    props.onFinish(data);
    setIsActiveCamera(true);
    setIsQrCardActive(false);
    onClose();
  };

  const generateQrCodeAndSave = async () => {
    await setStopCam(true);
    const newCode = `${props.player.id}`;
    setCode(newCode);
    setIsActiveCamera(false);
    setIsQrCardActive(true);
    setOpenAlert(false);
    setOpen(true);
    props.saveHash(newCode);
  };

  const viewQrCodeAndSave = async () => {
    await setStopCam(true);
    setCode(props.player.accessHash);

    setIsActiveCamera(false);
    setIsQrCardActive(true);
    setOpenAlert(false);
    setOpen(true);
  };

const downloadAndSaveCard = async () => {
  const { player } = props;
  const qrCodeImage = document.getElementById('qr-card');
  
  const canvas = await html2canvas(qrCodeImage, {
    scale: 1, 
    useCORS: true,
    logging: false, 
    allowTaint: true, 
    imageTimeout: 0,
  });
  
  // Convert canvas to PNG
  const file = canvas.toDataURL('image/jpeg');
  
  // Download the image
  download(
    file,
    `card-${player.firstName}-${player.surname}.png`
      .toLowerCase()
      .split(' ')
      .join('-')
  );
};

  const openHasAccessHashAlert = () => {
    setOpenAlert(true);
  };

  const beColumnOnMobile = css`
      @media (max-width: 960px) {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0 20px;
        button {
          margin: 10px 0;
        }
        justify-content: center;
      }
        `;
  // function isSHA256(code) {
  //   const keySHA256Regex = /^[a-fA-F0-9]{64}$/;
  //   const testCode = keySHA256Regex.test(code);
  //   setCodeSHA256(testCode);
  // }

  // useEffect(() => {
  //   isSHA256(props.player.accessHash);
  // }, [props.player.accessHash]);

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {isActiveCamera && !open && (
        <DialogTitle id="alert-dialog-title">Vincular QRCode</DialogTitle>
      )}
      {open && (
        <>
          <DialogTitle id="alert-dialog-title">Cartão Virtual</DialogTitle>
          {isQrCardActive && (
            <Button
              style={{
                justifyContent: 'start',
                paddingLeft: 25,
              }}
              onClick={() => {
                setOpen(false);
                setIsQrCardActive(false);
                setIsActiveCamera(true);
              }}
            >
              <KeyboardBackspaceIcon />
              Voltar
            </Button>
          )}
        </>
      )}
      <DialogContent>
        {isActiveCamera && (
          <>
            <DialogContentText id="alert-dialog-description">
              Aproxime o QRCode da câmera para vincular ao jogador.
            </DialogContentText>
            <QrCodeReader onScan={afterRead} stopCam={!props.open || stopCam} />
          </>
        )}
        {isQrCardActive && (
          <div id="qr-card">
            <QrCardLayout
              qrcode={
                <QRCode
                  value={code}
                  style={{ width: '100%', height: 'auto' }}
                />
              }
              title={`${props.player.firstName} ${props.player.surname}`}
            />
          </div>
        )}
      </DialogContent>
      <DialogActions className={beColumnOnMobile}>
        {props.player.id && (
          <>
            {!isQrCardActive && !open && (
              <Button
                onClick={
                  props.player.accessHash
                    ? openHasAccessHashAlert
                    : generateQrCodeAndSave
                }
                color="secondary"
                variant="contained"
                autoFocus
              >
                {!props.player.accessHash
                  ? 'Gerar cartão'
                  : 'Gerar novo cartão'}
              </Button>
            )}
            {(isQrCardActive || open) && (
              <Button
                onClick={downloadAndSaveCard}
                color="secondary"
                variant="contained"
                autoFocus
              >
                Baixar cartão
              </Button>
            )}
            {props.player.accessHash && !open ? (
              <Button
                onClick={viewQrCodeAndSave}
                color="secondary"
                variant="contained"
                autoFocus
              >
                Visualizar cartão
              </Button>
            ) : (
              ''
            )}
          </>
        )}
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
        <AlertDialog
          reload
          open={openAlert}
          title="Você tem certeza que deseja vincular outro QRCode para este jogador?"
          onClose={() => setOpenAlert(false)}
          onCancel={() => setOpenAlert(false)}
          onAgree={generateQrCodeAndSave}
        >
          Essa ação é irreversível e o QRCode antigo perderá instantaneamente
          acesso a todas as plataformas Gamefik.
        </AlertDialog>
      </DialogActions>
    </Dialog>
  );
}
export default PlayerQrCodeDialog;
