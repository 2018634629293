import Client from "../client";

const STORAGE_WORD = "CONFIG_SETTINGS";

export type ISettings = {
  qrid: boolean;
  rfid: boolean;
  simple: boolean;
  username: string;
  userData: any;
  intercomHash: string;
  feed: boolean;
  isNewAuth: boolean;
  sound_disabled: boolean;
  ranking_disabled: boolean;
  shopping_disabled: boolean;
  disabled: boolean;
  availablePoints: string[];
  points: {
    id: string;
    name: string;
    source: string;
  }[];
  schoolName: string;
  game_controlado: boolean;
  max_professor_points: number;
  professor_can_select_classes: boolean;
  professor_missions: boolean;
  missoes2: boolean;
  missoes2ComprovantesComIa: boolean;
  light: boolean;
  levels: Record<
    string,
    {
      id: string;
      name: string;
      order: number;
      source: string;
      objective: {
        id: string;
        objectiveId: string;
        name: string;
        desc: string;
      };
      range: {
        min: number;
        max: number;
      };
      rewards: {
        coin: number;
      };
    }[]
  >;
  is_pro: boolean;
  purchasesCounter: number;
  isAdmin: boolean;
  isCompleteRegister: boolean;
  accountsIds: string[];
  accountsDetails: {
    _id: string;
    name: string;
    isPro: boolean;
    world: string;
    email: string;
    ultimaTempUnlock?: string;
    stripe?: any;
    invoices?: any[];
  }[];
};

export default class Settings {
  static cachedSettings: any;

  static async loadSettings(): Promise<ISettings> {
    if (typeof window === "undefined") return;
    const client = new Client();
    const settings = await client.getSettings();
    if (!settings) {
      return;
    }
    const purchases = await client.getShoppingPurchases();
    const purchasesCounter = purchases.filter(el => el.status === "created")
      .length;
    settings.purchasesCounter = purchasesCounter;
    settings.isAdmin = true;
    if (settings.isNewAuth) {
      const userData = await client.getLoggedUserData();
      settings.isCompleteRegister =
        (userData.name && userData.birthDate && userData.gender) !== undefined;
      settings.isAdmin = userData.groups.includes("admin");
      settings.accountsIds = userData.accounts;
      settings.accountsDetails = userData.accountsDetails;
      settings.intercomHash = userData.intercomHash;
      settings.userData = userData;
    }
    localStorage.setItem(STORAGE_WORD, JSON.stringify(settings));
    this.cachedSettings = settings;

    return settings;
  }

  static async unloadSettings() {
    if (typeof window === "undefined") return;

    localStorage.removeItem(STORAGE_WORD);
    this.cachedSettings = undefined;
  }

  static getSettings(): ISettings {
    if (typeof window === "undefined") return;
    if (this.cachedSettings) return this.cachedSettings;
    const settings = localStorage.getItem(STORAGE_WORD);
    if (settings) {
      this.cachedSettings = JSON.parse(settings);
      return JSON.parse(settings);
    }
    return false as any;
  }

  static isEnable(key) {
    if (typeof window === "undefined") return;

    try {
      const settings = this.getSettings();
      if (!settings) {
        this.loadSettings();
      }
      return Boolean(settings[key]);
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  static getData(key) {
    if (typeof window === "undefined") return;
    try {
      const settings = this.getSettings();
      if (!settings) {
        this.loadSettings();
      }
      return settings[key];
    } catch (error) {
      console.error(error);
      return false;
    }
  }
}
