import React from 'react';
import { css } from 'emotion';

export default React.forwardRef(function Interrogation(props, ref) {

    const styled = css`    
    border: 2px solid #865AC8;
    border-radius: 100%;
    width: ${props.size || 18}px;
    height: ${props.size || 18}px;
    color: #865AC8;  
    display: inline-block;
    font-weight: bold;
    margin: 0 5px;
    cursor: var(--cursor-pointer);
    position: relative;

    div {
        display: flex;
        justify-content: center;
        align-items: center;        
        position: absolute;
        width: 100%;
        height: 100%;
        font-size: ${props.fontSize || 13}px;
    }
    `
    return (
        <div {...props} ref={ref} className={styled}><div>?</div></div>
    )
})
