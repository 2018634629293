import React from 'react';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

import AttitudeIcon from '../attitude-icons';
import MultipliableDialog from '../multipliable-dialog';

function AttitudeList(props) {
  const [selectedAttitudes, setSelectedAttitudes] = React.useState({});
  const [
    modalMultipliableOpen,
    setModalMultipliableOpenOnAttitude,
  ] = React.useState(false);
  const getAmount = attitude => {
    try {
      return selectedAttitudes[attitude.id].amount
        ? `(${selectedAttitudes[attitude.id].amount}x)`
        : '';
    } catch (error) {
      return '';
    }
  };

  const getPointsText = currentGame => {
    if (!currentGame || !currentGame.rewards) {
      return '';
    }
    const rewardsArray = [];
    Object.keys(currentGame.rewards).map(key =>
      rewardsArray.push(`${key}: ${currentGame.rewards[key]}`)
    );
    return (
      <React.Fragment>
        <Typography component="span" variant="body2" color="textPrimary">
          {currentGame.name}{' '}
          {currentGame.accomplishments &&
            '( ' + currentGame.accomplishments + ' )'}{' '}
          :
        </Typography>
        {` ${rewardsArray.join(' | ')}`}
      </React.Fragment>
    );
  };

  const switchAttitudeBy = attitude => {
    if (selectedAttitudes[attitude.id]) {
      delete selectedAttitudes[attitude.id];
    } else {
      selectedAttitudes[attitude.id] = attitude;
    }
    setSelectedAttitudes(selectedAttitudes);
    props.onSelect(selectedAttitudes);
  };

  const handleCheck = el => {
    const attitude = props.attitudes.find(at => at.id === el.target.value);
    if (!attitude.multipliable) {
      switchAttitudeBy(attitude);
      return;
    }
    if (el.target.checked) {
      setModalMultipliableOpenOnAttitude(attitude);
      return;
    }
    switchAttitudeBy(attitude);
  };

  const handleCheckMultipliable = attitude => {
    switchAttitudeBy(attitude);
    setModalMultipliableOpenOnAttitude(false);
  };

  const getCurrentGameRewards = (attitude, game) => {
    const currentGame = attitude.games.find(g => g.id === game);
    if (currentGame) {
      return currentGame;
    }
    return null;
  };

  const checkMissionComplete = attitude => {
    if (!props.completeMissions) {
      return false;
    }
    if (!props.completeMissions[attitude.id]) {
      return false;
    }
    const currentGame = attitude.games.find(g => g.id === props.games[0]);
    if (!currentGame) {
      return false;
    }
    if (!currentGame.noRecurrent) {
      return false;
    }
    return true;
  };

  return (
    <div>
      <List dense>
        {props.attitudes &&
          props.attitudes.map(at => (
            <Button
              style={{
                width: '100%',
                display: 'flex',
                padding: 0,
                margin: 0,
                fontSize: 'inherit',
                lineHeight: 'inherit',
                fontFamily: 'inherit',
                color: 'inherit',
                border: 'none',
                textTransform: 'none',
              }}
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                if (checkMissionComplete(at)) {
                  return;
                }
                if (at.multipliable) {
                  setModalMultipliableOpenOnAttitude(at);
                  return;
                }
                handleCheck({ target: { value: at.id } });
              }}
            >
              <ListItem
                key={at.id}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                {checkMissionComplete(at) ? (
                  <Checkbox disabled />
                ) : (
                  <Checkbox
                    // onClick={handleCheck}
                    checked={Boolean(selectedAttitudes[at.id])}
                    value={at.id}
                  />
                )}
                <h1>{at.accomplishments}</h1>
                <ListItemText
                  primary={`${at.name} ${getAmount(at)}`}
                  secondary={props.games.map(game => (
                    <>
                      <Typography component="span" variant="body2">
                        {getPointsText(getCurrentGameRewards(at, game))}
                      </Typography>
                      <br />
                    </>
                  ))}
                />
                <h2
                  style={{
                    flex: '1',
                  }}
                 />

                <ListItemIcon
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  {checkMissionComplete(at) ? (
                    <AttitudeIcon type="completeMission" label />
                  ) : (
                    <AttitudeIcon type={at.type} />
                  )}
                </ListItemIcon>
              </ListItem>
            </Button>
          ))}
      </List>

      {modalMultipliableOpen && (
        <MultipliableDialog
          open={Boolean(modalMultipliableOpen)}
          attitude={modalMultipliableOpen}
          onClose={attitude => {
            return setModalMultipliableOpenOnAttitude(false);
          }}
          onFinish={data => handleCheckMultipliable(data)}
        />
      )}
    </div>
  );
}

export default AttitudeList;
