import EmojiEvents from "@material-ui/icons/EmojiEvents";
import PublicIcon from "@material-ui/icons/Public";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import { css } from "emotion";

const style = css`
  text-align: center;
`;

const iconConfigList = {
  good: {
    label: "Atitude positiva",
    color: "#46DFA1",
    icon: ThumbUpIcon
  },
  bad: {
    label: "Atitude negativa",
    color: "error",
    icon: ThumbDownIcon
  },
  objective: {
    label: "Missão",
    color: "primary",
    icon: PublicIcon
  },
  completeMission: {
    color: "secondary",
    icon: EmojiEvents
  }
};

export default function AttitudeIcon({
  type,
  label
}: {
  type: string;
  label?: boolean;
}) {
  const iconConfig = iconConfigList[type];
  return (
    <div title={iconConfig.label} className={style}>
      <iconConfig.icon
        color={iconConfig.color}
        fontsize="small"
        style={{
          color: iconConfig.color
        }}
      />

      {label && (
        <div>
          <small>{iconConfig.label}</small>
        </div>
      )}
    </div>
  );
}
