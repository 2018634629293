import React from 'react';
import { css } from 'emotion';

const style = css`
  width: 342px;
  height: 600px;
  background-image: url('/assets/img/bg-qr-code-card.png');
  background-position: center;
  background-size: cover;
  padding: 9px;
  margin: 0 auto;
  img {
    width: 100%;
  }
  .header {
    display: flex;
    .logo {
      padding: 0 15px;
      margin-top: 65px;
      width: 50%;
    }
    .planet {
      margin-top: 48px;
      width: 50%;
      text-align: right;
      img,
      canvas {
        max-width: 80%;
      }
    }
  }
  .body {
    margin-top: -75px;
    background: rgba(75, 55, 134, 0.5);
    border-radius: 10px;
    padding: 15px 15px;
    .qrcode-container {
      background: #fff;
      border-radius: 10px;
      width: 100%;
      padding: 30px;
      z-index: 2;
      position: relative;
      margin-bottom: 12px;
    }
    .field {
      margin: 3px 0;
      .label {
        color: #fff;
        font-size: 0.6rem;
        margin: 3px 0;
      }
      .data {
        color: #fff;
        background: rgba(255, 255, 255, 0.2);
        font-family: 'Fredoka One';
        border-radius: 5px;
        padding: 3px 7px;
      }
    }
  }
`;

export default function QrCardLayout(props) {
  return (
    <div className={style}>
      <div className="header">
        <div className="logo">
          <img src="/assets/img/gamefik-logo-qrcode-card.png" />
        </div>
        <div className="planet">
          <img src="/assets/img/planet-qrcode-card.png" />
        </div>
      </div>
      <div className="body">
        <div className="qrcode-container">{props.qrcode}</div>
        <div className="field">
          <div className="label">Nome do aluno:</div>
          <div className="data">{props.title}</div>
        </div>
      </div>
    </div>
  );
}
