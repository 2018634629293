import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';

type Props = {
  children?: React.ReactNode | React.ReactNode[]
  onCancel?: () => void
  onAgree?: () => void
  onClose?: () => void
  open: boolean
  textButton?: string
  title?: string
  disabledButton?: boolean
  textButtonClose?: string
  noFullScreen?: boolean
  onlyCancelOnMobile?: boolean
  reload?: boolean,
  fullWidth?: boolean
}

export default function AlertDialog(props: Props) {
  const fullScreen = useMediaQuery('(min-width:600px)');

  const cancelClassName = props.onlyCancelOnMobile ? 'hide-in-desktop' : '';
  return (
    <Dialog
      open={props.open}
      fullScreen={props.noFullScreen ? false : !fullScreen}
      scroll="paper"
      fullWidth={props.fullWidth}
      onClose={props.onClose}
      aria-labelledby="responsive-dialog-title"
      disableEnforceFocus
    >
      {props.title && <DialogTitle>{props.title}</DialogTitle>}
      <DialogContent>
        <DialogContentText
          style={{ display: 'flex', flexDirection: 'column' }}
          id="alert-dialog-description"
        >
          {props.children}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {props.onCancel && (
          <div className={cancelClassName}>
            <Button onClick={props.onCancel} color="default" variant="outlined">
              {props.textButtonClose ? props.textButtonClose : 'Cancelar'}
            </Button>
          </div>
        )}
        {props.onAgree && (
          <Button
            disabled={props.disabledButton}
            onClick={async () => {
              await props.onAgree();
              if (props.reload) window.location.reload();
            }}
            color="primary"
            variant="contained"
          >
            {props.textButton ? props.textButton : 'Aceitar'}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
