import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';

const useStyleTooltip = makeStyles(() => ({
  arrow: {
    color: '#4b3786',
  },
  tooltip: {
    fontSize: 14,
    backgroundColor: '#4b3786',
    textAlign: 'center',
  },
}));

function StyleTooltip(props) {
  const classes = useStyleTooltip();
  return <Tooltip arrow classes={classes} {...props} />;
}

export default function BalloonTooltip(props) {
  return (
      <StyleTooltip disableTouchListener={true} title={props.title} placement="top-start" arrow>
          {props.children}
      </StyleTooltip>
  )
}