import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import moment from 'moment';

type Points = {
  coin?: number
  xp?: number
}

type HistoryItem = {
  id: string
  createdAt: Date
  gameName?: string
  email?: string
  points?: Points
  title: string
  isGame?: boolean
}

type HistoryListProps = {
  title: string,
  history: HistoryItem[]
}

export default function HistoryList({ history, title }: HistoryListProps) {
  return (
    <Table>
      <TableBody>
        {title && history?.length > 0 && (
          <TableRow>
            <TableCell colSpan={2}>
              <Typography variant="h5">{title}</Typography>
            </TableCell>
          </TableRow>
        )}
        {history?.length > 0 ? history.map(entry => (
          <HistoryItem key={entry.id} {...entry} />)
        ) : null}
      </TableBody>
    </Table>
  );
}

const HistoryItem = ({ title, points, gameName, createdAt, email, id, isGame }: HistoryItem) => {

  const showPoints = () => {
    if (!points) return null

    if (!title.toLowerCase().includes('missão')) return getPointsText(points);

    if (Object.values(points).some(point => point != 0)) return getPointsText(points);

    return null;
  };

  if (isGame) {
    return (
      <TableRow key={id}>
        <TableCell>
          <small>
            <strong>Atividades: </strong> {title}
            <br />
          </small>
          <small>
            <strong>Data: </strong>
            {moment(createdAt).format('DD/MM/YYYY HH:mm:ss')}
          </small>
        </TableCell>
        <TableCell align="right">{showPoints()}</TableCell>
      </TableRow>
    )
  }

  return (
    <TableRow key={id}>
      <TableCell>
        <small>
          <strong>Atividades: </strong> {title}
          <br />
        </small>
        {
          email && (
            <>
              <small>
                <strong>Aplicado por:</strong> {email ?? "Desconhecido"}
              </small>
              <br />
            </>
          )
        }
        {gameName ?
          <small>
            <strong>Jogo: </strong>
            {gameName}
          </small>
          : null}
        <br />
        <small>
          <strong>Data: </strong>
          {moment(createdAt).format('DD/MM/YYYY HH:mm:ss')}
        </small>
      </TableCell>
      <TableCell align="right">{showPoints()}</TableCell>
    </TableRow>
  );
};

const getPointsText = (points: Points) => {
  return (
    <div>
      {Object.entries(points).map(([key, value], i) => (
        <div key={i}>
          {value >= 0 ? (
            <ArrowDropUpIcon
              style={{ marginBottom: "-8px" }}
              color="secondary"
            />
          ) : (
            <ArrowDropDownIcon
              style={{ marginBottom: "-8px" }}
              color="error"
            />
          )}
          {`${value} ${key}`}
        </div>
      ))}
    </div>
  );
};
