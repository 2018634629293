import React from 'react';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

function SnackAlert(props) {
  return (
    <Snackbar
      open={props.open}
      autoHideDuration={props.autoHideDuration ? props.autoHideDuration : 3000}
      onClose={props.onClose}
      anchorOrigin={props.anchorOrigin}
    >
      <Alert 
      onClose={props.onClose}
      severity={props.type} 
      icon={props.icon} 
      style={props.alertStyle}
      >
        {props.children}
      </Alert>
    </Snackbar>
  );
}

export default SnackAlert;
