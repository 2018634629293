import { useEffect, useState } from "react";

import { TextField, Button } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { css } from "emotion";

import Client from "../../../services/client";
import Settings, { ISettings } from "../../../services/settings";
import RocketLoading from "../../atoms/loading";
import IntegratedTooltip from "../integrated-tooltip";

const client = new Client();

const style = css`
  padding: 10px 0;
  .input {
    padding: 10px 0;
  }
`;

export default function AdvancedSettingsForm() {
  const [loading, setLoading] = useState(true);
  const [settings, setSettings] = useState<ISettings>();
  const start = async () => {
    const newSettings = await client.getSettings();
    setSettings(newSettings);
    setLoading(false);
  };

  useEffect(() => {
    start();
  }, []);

  const handleSwitch = async (event, override) => {
    try {
      setLoading(true);
      const newSettings = {
        ...settings,
        [event.target.name]:
          typeof override === "function"
            ? override(!settings[event.target.name])
            : !settings[event.target.name]
      };
      setSettings(newSettings);

      await client.putSettings(newSettings);
      await Settings.loadSettings();
    } catch (error) {
      console.error(error);
      setLoading(false);
    } finally {
      window.location.reload();
    }
  };

  const handleChange = async event => {
    try {
      const newSettings = {
        ...settings,
        [event.target.name]: event.target.value
      };
      setSettings(newSettings);
    } catch (error) {
      console.log(error);
    }
  };

  if (!settings) {
    return <></>
  }

  return (
    <form autoComplete="off" className={style}>
      {
        loading && <RocketLoading />
      }
      <>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell component="th">
                  <strong>Item</strong>
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {Constants.isTestEnvironment() && (
                <TableRow hover>
                  <TableCell>
                    <small> APENAS EM HMG </small>

                    <br />
                    Login sem QR Code
                  </TableCell>
                  <TableCell align="right">
                    <Switch
                      checked={settings?.simple}
                      onChange={handleSwitch}
                      name="simple"
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  </TableCell>
                </TableRow>
              )}

              {/* {Constants.isTestEnvironment() && (
                <TableRow hover>
                  <TableCell>
                    <small> APENAS EM HMG </small>

                    <br />
                    Gamefik light
                  </TableCell>
                  <TableCell align="right">
                    <Switch
                      checked={settings.light}
                      onChange={handleSwitch}
                      name="light"
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  </TableCell>
                </TableRow>
              )} */}

              <TableRow hover>
                <TableCell>Limite de pontos do professor</TableCell>
                <TableCell align="right">
                  <Switch
                    checked={Boolean(settings.max_professor_points)}
                    onChange={e => handleSwitch(e, a => (a ? 300 : 0))}
                    name="max_professor_points"
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                  {settings.max_professor_points && (
                    <>
                      <TextField
                        type="number"
                        value={settings.max_professor_points}
                        onChange={e => handleChange(e)}
                        name="max_professor_points"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                      <Button
                        onClick={async () => {
                          setLoading(true);
                          await client.putSettings(settings);
                          await Settings.loadSettings();
                          setLoading(false);
                          window.location.reload();
                        }}
                      >
                        Atualizar
                      </Button>
                    </>
                  )}
                </TableCell>
              </TableRow>

              {Constants.isTestEnvironment() && (
                <TableRow hover>
                  <TableCell>
                    <small> APENAS EM HMG </small>

                    <br />
                    Game controlado
                  </TableCell>
                  <TableCell align="right">
                    <Switch
                      checked={settings.game_controlado}
                      onChange={handleSwitch}
                      name="game_controlado"
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  </TableCell>
                </TableRow>
              )}

              {
                Constants.isTestEnvironment() && <TableRow hover>
                  <TableCell>
                    <small> APENAS EM HMG </small>

                    <br />
                    Missões 2.0 comprovantes com IA
                  </TableCell>
                  <TableCell align="right">
                    <Switch
                      checked={settings?.missoes2ComprovantesComIa}
                      onChange={handleSwitch}
                      name="missoes2ComprovantesComIa"
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  </TableCell>
                </TableRow>
              }

              <TableRow hover>
                <TableCell>Missões 2.0</TableCell>
                <TableCell align="right">
                  <Switch
                    checked={settings?.missoes2}
                    onChange={handleSwitch}
                    name="missoes2"
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                </TableCell>
              </TableRow>

              <TableRow hover>
                <TableCell>Professor pode selecionar as proprias turmas</TableCell>
                <TableCell align="right">
                  <Switch
                    checked={settings?.professor_can_select_classes}
                    onChange={handleSwitch}
                    name="professor_can_select_classes"
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                </TableCell>
              </TableRow>

              <TableRow hover>
                <TableCell>Missões do professor</TableCell>
                <TableCell align="right">
                  <Switch
                    checked={settings.professor_missions}
                    onChange={handleSwitch}
                    name="professor_missions"
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                </TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell>Sons no aplicativo</TableCell>
                <TableCell align="right">
                  <Switch
                    checked={!settings.sound_disabled}
                    onChange={handleSwitch}
                    name="sound_disabled"
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                </TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell>
                  Ranking visível para os alunos
                  <IntegratedTooltip title="Ao desativar essa opção, o ranking passa a ficar oculto para os jogadores." />
                </TableCell>
                <TableCell align="right">
                  <Switch
                    checked={!settings.ranking_disabled}
                    onChange={handleSwitch}
                    name="ranking_disabled"
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                </TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell>
                  Shopping acessível para os alunos
                  <IntegratedTooltip title="Ao desativar essa opção, a loja passa a ficar  oculta para os jogadores." />
                </TableCell>
                <TableCell align="right">
                  <Switch
                    checked={!settings.shopping_disabled}
                    onChange={handleSwitch}
                    name="shopping_disabled"
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </>
    </form>
  );
}

type LocalStorageKeys =
  | "complete-list-of-players"
  | "complete-list-of-attitudes"
  | "complete-list-of-missions"
  | "filtered-list-of-attitudes"
  | "complete-list-of-games"
  | "badAttitudes"
  | "goodAttitudes"
  | "objectives"
  | "completeMissions"
  | "collapsed"
  | "complete-list-of-rewards"
  | "userAccessLevel"
  | "AllClasses"
  | "raw-list-of-players";

export class Constants {
  static LSK_AllClasses: LocalStorageKeys = "AllClasses";
  static LSK_UserAccessLevel: LocalStorageKeys = "userAccessLevel";
  static LSK_Collapsed: LocalStorageKeys = "collapsed";
  static LSK_GamesSelect = (multiple: any) => {
    return "GamesSelect games" + (multiple ? " multiple" : "");
  };
  static LSK_ClassesSelect = (multiple: any) => {
    return "ClassesSelect classes" + (multiple ? " multiple" : "");
  };
  static LSK_CompletedMissions: LocalStorageKeys = "completeMissions";
  static LSK_OldMissions: LocalStorageKeys = "objectives";
  static LSK_BadAttitudes: LocalStorageKeys = "badAttitudes";
  static LSK_GoodAttitudes: LocalStorageKeys = "goodAttitudes";
  static LSK_Games: LocalStorageKeys = "complete-list-of-games";
  static LSK_Missions: LocalStorageKeys = "complete-list-of-missions";
  static LSK_RawPlayers: LocalStorageKeys = "raw-list-of-players";
  static LSK_FullPlayers: LocalStorageKeys = "complete-list-of-players";
  static LSK_Rewards: LocalStorageKeys = "complete-list-of-rewards";
  static LSK_Attitudes: LocalStorageKeys = "complete-list-of-attitudes";
  static LSK_Attitudes_Filtered: LocalStorageKeys =
    "filtered-list-of-attitudes";

  static isTestEnvironment() {
    return (
      Constants.hostName() === "localhost" ||
      Constants.hostName() === "hmg-manager.gamefik.com"
    );
  }

  static hostName() {
    if (typeof window === "undefined") {
      return "";
    }
    return window.location.hostname;
  }
}
