import { css } from 'emotion';
import { QrcodeScan } from 'mdi-material-ui';
import React from 'react';

const savedStyle = css`
  opacity: 0.5;
`;

const QRCodeIcon = ({ exists }) => {
  return (
    <QrcodeScan
      fontSize="small"
      className={
        !exists
          ? savedStyle
          : css`
              color: lightgreen;
            `
      }
    />
  );
};

export default QRCodeIcon;
