import { createTheme } from '@material-ui/core';

export const Colors = {
  green: '#55FCB9',
  purple: '#4b3786',
  lightPurple: '#634f9d',
  red: '#ff9494',
  white: '#fff',
  blue: "#17C1FC"
};

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1500,
    },
  },

  overrides: {
    MuiContainer: {
      maxWidthXl: {
        '@media (min-width:1500px)': {
          maxWidth: '1500px',
        },
      },
    },
  },
  palette: {
    // type: 'dark',
    contrastThreshold: 3,
    tonalOffset: 0.2,
    secondary: {
      main: Colors.green,
      contrastText: Colors.purple,
    },
    primary: {
      light: Colors.lightPurple,
      main: Colors.purple,
      // dark: will be calculated from palette.secondary.main,
      contrastText: Colors.white,
    },
    error: {
      main: Colors.red,
    },
  },
});

export default theme;
