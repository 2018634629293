/* eslint-disable no-useless-catch */

import axios, { AxiosRequestConfig } from "axios";
import fetch from "isomorphic-unfetch";

import { IMissaoComprovante } from "@/interfaces/missao_comprovante";
import { GiftCardToBeBought } from "@/interfaces/order";

import AuthService from "../auth";

export const BASE_URL = process.env.NEXT_PUBLIC_API_V2;

export default class Client {
  static authenticatedRequest = new Client().authenticatedRequestOnAxios;
  async authenticatedRequest(
    method: "GET" | "POST" | "PUT" | "DELETE",
    path: string,
    data = false as any,
    queryParams: Record<string, any> = false as any
  ): Promise<any> {
    let headers = {
      "Content-Type": "application/json"
    } as Record<string, string>;

    const token = AuthService.isLogged();
    if (token) {
      headers = { ...headers, Authorization: token };
    }
    let config = {
      method,
      headers
    } as Record<string, any>;
    if (data) {
      config = { ...config, body: JSON.stringify(data) };
    }
    let queryParamsString = "";
    if (queryParams) {
      Object.keys(queryParams).map(el => {
        queryParamsString += `${el}=${queryParams[el]}&`;
      });
    }
    try {
      const response = await fetch(
        `${BASE_URL}/${path}?${queryParamsString}`,
        config
      );
      const responseJSON = await response.json();
      if (response.ok) {
        return responseJSON;
      }
      return {
        ...responseJSON,
        error: responseJSON,
        response: responseJSON
      };
    } catch (error) {
      return {
        error: error.message
      };
    }
  }

  async authenticatedRequestOnAxios({
    method,
    path,
    data,
    headers
  }: {
    method: AxiosRequestConfig["method"];
    path: string;
    data?: any;
    headers?: AxiosRequestConfig["headers"];
  }) {
    let authHeaders = {
      "Content-Type": "application/json"
    } as AxiosRequestConfig["headers"];
    const token = AuthService.isLogged();
    if (token) {
      authHeaders = { ...authHeaders, Authorization: token };
    }

    const response = await axios.request({
      url: `${BASE_URL}/${path}`,
      method: method,
      headers: {
        ...authHeaders,
        ...headers
      },
      data: data,
      responseType: "text"
    });
    return response;
  }

  async requestAxios(formdata) {
    try {
      const token = AuthService.isLogged();
      let config = null;
      if (token) {
        config = { headers: { Authorization: token } };
      }

      const resp = await axios.post(
        `${BASE_URL}/shopping/items/image/create`,
        formdata,
        config
      );
      return resp.data;
    } catch (e) {
      throw e;
    }
  }

  async changeSessionOnAccount({ token, accountId, world }) {
    const response = await this.authenticatedRequest(
      "POST",
      `change-session-on-account`,
      {
        token,
        accountId,
        world
      }
    );
    if (!response.error) {
      return response;
    }
    throw response.error;
  }

  async getBillingUrl() {
    const response = await this.authenticatedRequest(
      "POST",
      `users/get-billing-url`
    );
    if (!response.error) {
      return response;
    }
    throw response.error;
  }

  async auth({ username, password, preferrAccount }) {
    const response = await this.authenticatedRequest("POST", `auth`, {
      username,
      password,
      preferrAccount
    });
    return response;
  }

  async reAuth({ preferrAccount }) {
    const response = await this.authenticatedRequest("POST", `reauth`, {
      preferrAccount
    });
    return response;
  }

  async getPlayers() {
    const response = await this.authenticatedRequest("GET", "players");
    if (!response.error) {
      return response;
    }
    return [];
  }

  async getPlayersByTeacher() {
    const response = await this.authenticatedRequest("GET", `players/teacher`);
    if (!response.error) {
      return response;
    }
    throw response;
  }

  async getSomePlayers(playersIds: string[]) {
    const response = await this.authenticatedRequest("POST", "some-players", {
      playersIds: playersIds
    });
    if (!response.error) {
      return response;
    }
    return [];
  }

  async getSomePlayersByTeacher(playersIds: string[]) {
    const response = await this.authenticatedRequest(
      "POST",
      `some-players/teacher`,
      {
        playersIds: playersIds
      }
    );
    if (!response.error) {
      return response;
    }
    throw response;
  }

  async getPlayer(pid) {
    const response = await this.authenticatedRequest(
      "GET",
      `players/show/${pid}`
    );
    if (!response.error) {
      return response;
    }
    return null;
  }

  async getPlayerByHash(hash) {
    const response = await this.authenticatedRequest(
      "GET",
      `players/by-hash/${hash}`
    );
    if (!response.error) {
      return response;
    }
    throw response.error;
  }

  async getClasses() {
    const response = await this.authenticatedRequest("GET", "classes");
    if (!response.error) {
      return response;
    }
    return [];
  }

  async getClass(pid) {
    const response = await this.authenticatedRequest(
      "GET",
      `classes/show/${pid}`
    );
    if (!response.error) {
      return response;
    }
    return null;
  }

  async postCreateClass(data) {
    const response = await this.authenticatedRequest(
      "POST",
      `classes/create`,
      data
    );
    if (!response.error) {
      return response;
    }
    throw response.error;
  }

  async postGetHistory() {
    try {
      const response = await this.authenticatedRequest("POST", `users/history`);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getRecurrences() {
    const response = await this.authenticatedRequest("GET", `users/recurrence`);
    if (!response.error) {
      return response;
    }
    throw response.error;
  }

  async postTempUnlock() {
    const response = await this.authenticatedRequest(
      "POST",
      `users/temp-unlock`
    );
    if (!response.error) {
      return response;
    }
    throw response.error;
  }

  async putUpdateClass(pid, data) {
    const response = await this.authenticatedRequest(
      "PUT",
      `classes/update/${pid}`,
      data
    );
    if (!response.error) {
      return response;
    }
    throw response.error;
  }

  async deleteClass(pid) {
    const response = await this.authenticatedRequest(
      "DELETE",
      `classes/delete/${pid}`
    );
    if (!response.error) {
      return response;
    }
    return {};
  }

  async getGames() {
    type Games = {
      id: string;
      name: string;
      coinsLimit?: number;
      points: {
        coin: number;
        xp: number;
        planet: number;
      };
    };

    const response = await this.authenticatedRequest("GET", "games");
    if (!response.error) {
      return response as Games[];
    }
    return [];
  }

  async getGamesInWorld() {
    const response = await this.authenticatedRequest(
      "GET",
      "games/list-levels"
    );
    if (!response.error) {
      return response;
    }
    return [];
  }

  async getGameLevelsInWorld(id) {
    const response = await this.authenticatedRequest(
      "GET",
      `games/${id}/levels`
    );
    if (!response.error) {
      return response;
    }
    return [];
  }

  async deleteGame(id) {
    const response = await this.authenticatedRequest("DELETE", `games/${id}`);
    if (!response.error) {
      return response;
    }
    return {};
  }

  async putGamesInWorld(data) {
    const response = await this.authenticatedRequest(
      "PUT",
      `games/update-world-games`,
      data
    );
    if (!response.error) {
      return response;
    }
    throw response.error;
  }

  async postCreateHash(playerId, hash) {
    const response = await this.authenticatedRequest(
      "POST",
      `players/create-hash/${playerId}`,
      { hash }
    );
    if (!response.error) {
      return response;
    }
    return {};
  }

  async postCreatePlayer(data) {
    const response = await this.authenticatedRequest(
      "POST",
      `players/create`,
      data
    );
    if (!response.error) {
      return response;
    }
    throw response.error;
  }

  async putUpdatePlayer(pid, data) {
    const response = await this.authenticatedRequest(
      "PUT",
      `players/update/${pid}`,
      data
    );
    if (!response.error) {
      return response;
    }
    throw response.error;
  }

  async putUpdatePlayerLogs(type, data) {
    const response = await this.authenticatedRequest(
      "PUT",
      `players/update-logs`,
      {
        type,
        data
      }
    );
    if (!response.error) {
      return response;
    }
    throw response.error;
  }

  async deletePlayer(pid) {
    const response = await this.authenticatedRequest(
      "DELETE",
      `players/delete/${pid}`
    );
    if (!response.error) {
      return response;
    }
    return {};
  }

  async getAttitudes() {
    const response = await this.authenticatedRequest("GET", `attitudes`);
    if (!response.error) {
      return response;
    }
    return null;
  }

  async getCompleteMissions(gameId, playerId) {
    const response = await this.authenticatedRequest(
      "GET",
      `players/complete-missions/${gameId}/${playerId}`
    );
    if (!response.error) {
      return response;
    }
    return null;
  }

  async getAttitude(id) {
    const response = await this.authenticatedRequest(
      "GET",
      `attitudes/show/${id}`
    );
    if (!response.error) {
      return response;
    }
    return null;
  }

  async postCreateAttitude(data, query?: any) {
    const response = await this.authenticatedRequest(
      "POST",
      `attitudes/create`,
      data,
      query
    );
    if (!response.error) {
      return response;
    }
    throw response;
  }

  async putUpdateAttitude(pid, data) {
    const response = await this.authenticatedRequest(
      "PUT",
      `attitudes/update/${pid}`,
      data
    );
    if (!response.error) {
      return response;
    }
    throw response.error;
  }

  async deleteAttitude(pid) {
    const response = await this.authenticatedRequest(
      "DELETE",
      `attitudes/delete/${pid}`
    );
    if (!response.error) {
      return response;
    }
    throw response.error;
  }

  async getAttitudesByGame(game) {
    const response = await this.authenticatedRequest(
      "GET",
      `attitudes/game/${game}`
    );
    if (!response.error) {
      return response;
    }
    return null;
  }

  async postAttitudesOnGame(game, data, query) {
    const response = await this.authenticatedRequest(
      "POST",
      `attitudes/apply/${game}`,
      data,
      query
    );
    if (!response.error) {
      return response;
    }
    throw response;
  }

  async getItems() {
    const response = await this.authenticatedRequest("GET", `shopping/items`);
    if (!response.error) {
      return response;
    }
    return null;
  }

  async postCreateItem(data) {
    const response = await this.authenticatedRequest(
      "POST",
      `shopping/items/create`,
      data
    );
    if (!response.error) {
      return response;
    }
    throw response.error;
  }

  async createImageItem(data) {
    const formdata = new FormData();
    formdata.append("file", data.file);
    try {
      const response = await this.requestAxios(formdata);
      return response;
    } catch (e) {
      throw e;
    }
  }

  async getItemById(id) {
    const response = await this.authenticatedRequest(
      "GET",
      `shopping/items/show/${id}`
    );
    if (!response.error) {
      return response;
    }
    return null;
  }

  async putUpdateItem(pid, data) {
    if (!data.startDate) {
      data.startDate = new Date();
    }
    const response = await this.authenticatedRequest(
      "PUT",
      `shopping/items/update/${pid}`,
      data
    );
    if (!response.error) {
      return response;
    }
    throw response.error;
  }

  async deleteItem(pid) {
    const response = await this.authenticatedRequest(
      "DELETE",
      `shopping/items/delete/${pid}`
    );
    if (!response.error) {
      return response;
    }
    throw response.error;
  }

  async getShoppingPurchases() {
    const response = await this.authenticatedRequest(
      "GET",
      `shopping/purchases`
    );
    if (!response.error) {
      return response;
    }
    return [];
  }

  async getShoppingPurchase(id) {
    const response = await this.authenticatedRequest(
      "GET",
      `shopping/purchases/show/${id}`
    );
    if (!response.error) {
      return response;
    }
    return null;
  }

  async createShoppingPurchase(data) {
    if (!data.startDate) {
      data.startDate = new Date();
    }
    const response = await this.authenticatedRequest(
      "POST",
      `shopping/purchases/create`,
      {
        data
      }
    );
    if (!response.error) {
      return response;
    }
    return null;
  }

  async changeShoppingPurchaseStatus(
    id,
    status,
    query,
    replaceBy?: GiftCardToBeBought
  ) {
    const response = await this.authenticatedRequest(
      "PUT",
      `shopping/purchases/change-status/${id}/${status}`,
      replaceBy ?? false,
      query
    );
    if (!response.error) {
      return response;
    }
    throw response.error;
  }

  async getRewards() {
    const response = await this.authenticatedRequest("GET", `rewards`);
    if (!response.error) {
      return response;
    }
    return null;
  }

  async getRewardsById(id) {
    const response = await this.authenticatedRequest(
      "GET",
      `rewards/show/${id}`
    );
    if (!response.error) {
      return response;
    }
    return null;
  }

  async postCreateRewards(data) {
    const response = await this.authenticatedRequest(
      "POST",
      `rewards/create`,
      data
    );
    if (!response.error) {
      return response;
    }
    throw response.error;
  }

  async putUpdateRewards(pid, data) {
    const response = await this.authenticatedRequest(
      "PUT",
      `rewards/update/${pid}`,
      data
    );
    if (!response.error) {
      return response;
    }
    throw response.error;
  }

  async deleteRewards(pid) {
    const response = await this.authenticatedRequest(
      "DELETE",
      `rewards/delete/${pid}`
    );
    if (!response.error) {
      return response;
    }
    throw response.error;
  }

  async getSettings() {
    const response = await this.authenticatedRequest("GET", `settings`);
    if (!response.error) {
      return response;
    }
    return false;
  }

  async putSettings(data) {
    const response = await this.authenticatedRequest("PUT", `settings`, data);
    if (!response.error) {
      return response;
    }
    return false;
  }

  async getFeed() {
    const response = await this.authenticatedRequest("GET", `feed/attitudes`);
    if (!response.error) {
      return response;
    }
    return false;
  }

  async changeRequestAttitudeStatus(id, status) {
    const response = await this.authenticatedRequest(
      "POST",
      `feed/attitudes/change-status/${id}/${status}`
    );
    if (!response.error) {
      return response;
    }
    throw response.error;
  }

  async getHistory(pid) {
    const response = await this.authenticatedRequest(
      "GET",
      `players/history/${pid}`
    );
    if (!response.error) {
      return response;
    }
    return null;
  }

  async getMissionHistory(pid) {
    const response = await this.authenticatedRequest(
      "GET",
      `players/mission-history/${pid}`
    );
    if (!response.error) {
      return response;
    }
    return null;
  }

  async getRewardsByGame(game) {
    const response = await this.authenticatedRequest(
      "GET",
      `rewards/game/${game}`
    );
    if (!response.error) {
      return response;
    }
    return null;
  }

  async postApplyRewardsOnPlayer(game, playerId, data) {
    const response = await this.authenticatedRequest(
      "POST",
      `rewards/game/${game}/apply/${playerId}`,
      data
    );
    if (!response.error) {
      return response;
    }
    throw response.error;
  }

  async getSurvey() {
    const response = await this.authenticatedRequest("GET", `survey/managers`);
    return response;
  }

  async postSurvey(surveyId, data) {
    const response = await this.authenticatedRequest(
      "POST",
      `survey/managers/${surveyId}`,
      data
    );
    return response;
  }

  async postResetGames(data) {
    const response = await this.authenticatedRequest(
      "POST",
      `games/reset-games-points`,
      data
    );
    if (!response.error) {
      return response;
    }
    throw response.error;
  }

  async postFreezeOldGamesPoints() {
    await this.authenticatedRequest("POST", `games/freeze-old-games-points`);
  }

  async createGame(data) {
    const response = await this.authenticatedRequest(
      "POST",
      `games/create-game`,
      data
    );
    if (!response.error) {
      return response;
    }
    throw response;
  }

  async updateGame(data) {
    const response = await this.authenticatedRequest(
      "PUT",
      `games/update-game`,
      data
    );
    if (!response.error) {
      return response;
    }
    throw response;
  }

  async forgotPassword(email) {
    const response = await this.authenticatedRequest(
      "POST",
      `forgot-password/${email}`
    );
    if (!response.error) {
      return response;
    }
    throw response;
  }

  async changePassword(uid, token, data) {
    const response = await this.authenticatedRequest(
      "POST",
      `change-password/${uid}/${token}`,
      data
    );
    if (!response.error) {
      return response;
    }
    throw response;
  }

  async getUsers<T>() {
    try {
      const response = await this.authenticatedRequest("GET", `users`);
      if (!response.error) {
        return response as T;
      }
    } catch (error) {
      throw error;
    }
  }

  async getLoggedUserData() {
    const response = await this.authenticatedRequest("GET", `users/info`);
    if (!response.error) {
      return response;
    }
    throw response.error;
  }

  async getUserLevelAccess() {
    const user = await this.getLoggedUserData();

    if (user?.groups?.includes("admin")) {
      return 100;
    }

    if (user?.groups?.includes("secretary")) {
      return 80;
    }

    return 30;
  }

  async getAccountData() {
    const response = await this.authenticatedRequest(
      "GET",
      `users/account/info`
    );
    if (!response.error) {
      return response;
    }
    throw response.error;
  }

  async getUserById(id) {
    const response = await this.authenticatedRequest("GET", `users/find/${id}`);
    if (!response.error) {
      return response;
    }
    throw response.error;
  }

  async updateUser(data) {
    const response = await this.authenticatedRequest(
      "PUT",
      "users/update",
      data
    );
    if (!response.error) {
      return response;
    }
    throw response.error;
  }

  async updateAccount(data) {
    const response = await this.authenticatedRequest(
      "PUT",
      "users/account/update",
      data
    );
    if (!response.error) {
      return response;
    }
    throw response.error;
  }

  async changeGroups(id, groups) {
    const response = await this.authenticatedRequest(
      "PUT",
      `users/change-group/${id}`,
      {
        groups
      }
    );
    if (!response.error) {
      return response;
    }
    throw response.error;
  }

  async sendInvitation(data) {
    return this.authenticatedRequest("POST", `users/create-and-invite`, data);
  }

  async sendNewPassword(data, userId, passwordToken) {
    const response = await this.authenticatedRequest(
      "POST",
      `valid-invite/${userId}/${passwordToken}`,
      data
    );
    if (!response.error) {
      return response;
    }
    throw response;
  }

  async removeUser(userId) {
    const response = await this.authenticatedRequest(
      "PUT",
      `users/removeAccount/${userId}`
    );
    if (!response.error) {
      return response;
    }
    throw response.error;
  }

  async registerAccount(data) {
    const response = await this.authenticatedRequest(
      "POST",
      `users/register-account`,
      data
    );
    if (!response.error) {
      return response;
    }
    throw response;
  }

  async createAccount(data) {
    const response = await this.authenticatedRequest("POST", `register`, data);
    if (!response.error) {
      return response;
    }
    throw response;
  }

  async verifyUserWithCode(userId, code, email, password) {
    const response = await this.authenticatedRequest(
      "POST",
      `valid-code/${userId}`,
      {
        code,
        email,
        password
      }
    );
    if (!response.error) {
      return response;
    }
    throw response;
  }

  async resendInvite(userId) {
    const response = await this.authenticatedRequest(
      "POST",
      `users/${userId}/resend-invite`
    );
    if (!response.error) {
      return response;
    }
    throw response;
  }

  async sendCode(userId) {
    const response = await this.authenticatedRequest(
      "POST",
      `send-code/${userId}`
    );
    if (!response.error) {
      return response;
    }
    throw response;
  }

  async createClassTeacher(data) {
    const response = await this.authenticatedRequest(
      "PUT",
      `users/update-classes`,
      data
    );
    if (!response.error) {
      return response;
    }
    throw response;
  }

  async getClassesByUserId(userId) {
    const response = await this.authenticatedRequest(
      "GET",
      `users/classes/${userId}`
    );
    if (!response.error) {
      return response;
    }
    throw response;
  }

  async getAllTeacherClassesPermissions(): Promise<ClassesPermission[]> {
    const response = await this.authenticatedRequest(
      "GET",
      `users/classes/all`
    );
    if (!response.error) {
      return response;
    }
    throw response;
  }

  async getClassesByTeacher() {
    const response = await this.authenticatedRequest("GET", `classes/teacher`);
    if (!response.error) {
      return response;
    }
    throw response;
  }

  async createRecurrence(data) {
    const response = await this.authenticatedRequest(
      "POST",
      `recurrence/create`,
      data
    );

    if (!response.error) {
      return response;
    }
    throw response;
  }

  async getRecurrence(attitudeId) {
    const response = await this.authenticatedRequest(
      "GET",
      `recurrence/${attitudeId}`
    );

    if (!response.error) {
      return response;
    }
    throw response;
  }

  async editRecurrence(id, data) {
    const response = await this.authenticatedRequest(
      "PUT",
      `recurrence/update/${id}`,
      data
    );

    if (!response.error) {
      return response;
    }
    throw response;
  }

  async deleteRecurrence(id) {
    const response = await this.authenticatedRequest(
      "DELETE",
      `recurrence/delete/${id}`
    );

    if (!response.error) {
      return response;
    }
    throw response;
  }

  async createResetGameRecurrence(data) {
    const response = await this.authenticatedRequest(
      "POST",
      `recurrence-reset-games/create`,
      data
    );

    if (!response.error) {
      return response;
    }
    throw response;
  }

  async editResetGameRecurrence(id, data) {
    const response = await this.authenticatedRequest(
      "PUT",
      `recurrence-reset-games/update/${id}`,
      data
    );

    if (!response.error) {
      return response;
    }
    throw response;
  }

  async getResetGameRecurrence() {
    const response = await this.authenticatedRequest("GET", `recurrence/all`);

    if (!response.error) {
      return response;
    }
    throw response;
  }
  async getGiftCards() {
    const response = await this.authenticatedRequest(
      "GET",
      `shopping/giftcards`
    );

    if (!response.error) {
      return response;
    }
    throw response;
  }

  async getGiftCardsAvailabes() {
    const response = await this.authenticatedRequest(
      "GET",
      `shopping/giftcards/availables`
    );

    if (!response.error) {
      return response;
    }
    throw response;
  }

  async buyGiftCards(payload) {
    const response = await this.authenticatedRequest(
      "POST",
      `shopping/giftcards/buy`,
      payload
    );
    if (!response.error) {
      return response;
    }
    throw response;
  }

  async generateQrCodes(world, payload) {
    const response = await this.authenticatedRequest(
      "POST",
      `${world}/qrcode`,
      payload
    );

    if (!response.error) {
      return response;
    }
    throw response;
  }
  async downloadQrCodes(world, payload) {
    const response = await this.authenticatedRequest(
      "POST",
      `${world}/qrcode/download`,
      payload
    );

    if (!response.error) {
      return response;
    }
    throw response;
  }

  async generateBlankQrCodes(amount) {
    const response = await this.authenticatedRequest(
      "GET",
      `authenticated/generate/qrcodes?amount=${amount}`
    );

    if (!response.error) {
      return response;
    }
    throw response;
  }
  async refundGiftCards(payload) {
    const response = await this.authenticatedRequest(
      "POST",
      `shopping/giftcards/refund`,
      payload
    );
    if (!response.error) {
      return response;
    }
    throw response;
  }

  async getWallet() {
    const response = await this.authenticatedRequest("GET", `shopping/wallet`);
    if (!response.error) {
      return response;
    }
    throw response;
  }

  async getWalletStatment(limit) {
    let url = `shopping/wallet/statements`;
    if (limit) {
      url = `${url}?limit=${limit}`;
    }
    const response = await this.authenticatedRequest("GET", url);

    if (!response.error) {
      return response;
    }
    throw response;
  }

  async getPayments() {
    const response = await this.authenticatedRequest(
      "GET",
      `shopping/payments`
    );
    if (!response.error) {
      return response;
    }
    throw response;
  }

  async getPaymentsById(id) {
    const response = await this.authenticatedRequest(
      "GET",
      `shopping/payments/show/${id}`
    );
    if (!response.error) {
      return response;
    }
    throw response;
  }

  async paymentsCreate(data) {
    const response = await this.authenticatedRequest(
      "POST",
      `shopping/payments/create`,
      data
    );
    if (!response.error) {
      return response;
    }
    throw response.error;
  }

  async paymentsCreateCreditCard(data) {
    const response = await this.authenticatedRequest(
      "POST",
      `shopping/payments/creditcard`,
      data
    );
    if (!response.error) {
      return response;
    }
    throw response.error;
  }

  async paymentsStatus(data) {
    const response = await this.authenticatedRequest(
      "POST",
      `shopping/payments/notification`,
      data
    );
    if (!response.error) {
      return response;
    }
    throw response.error;
  }

  async sendToShopping(payload) {
    const response = await this.authenticatedRequest(
      "POST",
      `shopping/giftcards/sendshop`,
      payload
    );
    if (!response.error) {
      return response;
    }
    throw response;
  }

  async sendEmailPayment({ type, paymentId, email }) {
    const response = await this.authenticatedRequest(
      "POST",
      `shopping/payments/sendemail/${type}/${paymentId}`,
      { email }
    );

    if (!response.error) {
      return response;
    }
    throw response;
  }

  async getCategoryRewards() {
    const response = await this.authenticatedRequest(
      "GET",
      `shopping/item-category`
    );
    if (!response.error) {
      return response;
    }
    throw response;
  }

  async categoryCreate(payload) {
    const response = await this.authenticatedRequest(
      "POST",
      `shopping/item-category/create`,
      payload
    );
    if (!response.error) {
      return response;
    }
    throw response;
  }

  async getIconsRewards() {
    const response = await this.authenticatedRequest(
      "GET",
      `shopping/items/image/list`
    );
    if (!response.error) {
      return response;
    }
    throw response;
  }

  async removeOrAddCurrency(payload) {
    const response = await this.authenticatedRequest(
      "POST",
      "bonus/apply-balance-change?notNotify=false&",
      payload
    );
    if (!response.error) {
      return response;
    }
    throw response;
  }

  // criar um endpoints para trazer so os do usuario
  async getAllReceipts(): Promise<IMissaoComprovante[]> {
    const response = await this.authenticatedRequest(
      "GET",
      "missoes2/comprovantes/get-all"
    );
    if (!response.error) {
      return response;
    }
    throw response;
  }

  async getCoinsUsedByTeacher(id: string) {
    const response = await this.authenticatedRequest(
      "GET",
      `missoes2/coins-used/${id}`
    );

    if (!response.error) {
      return response;
    }

    throw response;
  }

  async getCoinsUsedPerClass() {
    const response = await this.authenticatedRequest(
      "GET",
      `dahsboard/coins-per-class`
    );

    if (!response.error) {
      return response;
    }

    throw response;
  }

  async getEngagmentPerYear() {
    const response = await this.authenticatedRequest(
      "GET",
      `dahsboard/engagement-per-year`
    );

    if (!response.error) {
      return response;
    }

    throw response;
  }

  async getMissionDataForChart() {
    const response = await this.authenticatedRequest(
      "GET",
      `dahsboard/missions`
    );

    if (!response.error) {
      return response;
    }

    throw response;
  }

  async getDashboardRewards() {
    const response = await this.authenticatedRequest(
      "GET",
      `dahsboard/rewards`
    );

    if (!response.error) {
      return response;
    }

    throw response;
  }

  async getDashboardMostRedeemedRewards() {
    const response = await this.authenticatedRequest(
      "GET",
      `dahsboard/most-redeemed-rewards`
    );

    if (!response.error) {
      return response;
    }

    throw response;
  }

  async getDashboardTeacherUsage() {
    const response = await this.authenticatedRequest(
      "GET",
      `dahsboard/teacher-usage`
    );

    if (!response.error) {
      return response;
    }

    throw response;
  }

  async getDashboardUserAccess() {
    const response = await this.authenticatedRequest(
      "GET",
      `dahsboard/user-access`
    );

    if (!response.error) {
      return response;
    }

    throw response;
  }

    async getDashboardMonthlyAccess() {
      const response = await this.authenticatedRequest(
        "GET",
        `dahsboard/total-access`
      );

      if (!response.error) {
        return response;
      }

      throw response;
    }

    async getDashboardAnaliseConta() {
    const response = await this.authenticatedRequest(
      "GET",
      `dahsboard/analytics-conta`
    );

    if (!response.error) {
      return response
    }

    throw response;
  }
}


export type AnaliseConta = {
  date: string;
  ticker: string;
  usuarios_cadastrados: number;
  porcentagem_de_alunos_que_compraram_nos_ultimos_15_dias: number;
  valor_total_em_coins_das_recompensas: number;
  numero_recompensas: number;
  ultimo_dia_de_uso: string;
  nome: string;
  numero_missoes2: number;
  valor_total_em_coins_da_economia: number;
  numero_de_usuarios: number;
  ultima_fatura: string;
  numero_pedidos: number;
  quantidade_de_usos_no_ultimo_mes: number;
  ultima_fatura_status: string;
  game_controlado: string;
  numero_estoque_de_recompensas: number;
  MRR: number;
  LTV: number;
  numero_jogadores_cadastrados: number;
  porcentagem_de_alunos_que_resgataram_saldo_nos_ultimos_15_dias: number;
  porcentagem_de_alunos_que_compraram_gift_card_nos_ultimos_15_dias: number;
  tipo: string;
  valor_total_em_coins_gastas_na_loja: number;
  numero_de_games: number;
  porcentagem_de_alunos_que_receberam_atitudes_nos_ultimos_15_dias: number;
  _id: string;
  id: string;
  numero_attitudes: number;
  porcentagem_de_alunos_que_receberam_missões_nos_ultimos_15_dias: number;
  numero_de_turmas: number;
  media_de_coins_por_aluno: number;
  porcentagem_de_alunos_que_resgataram_missões_nos_ultimos_15_dias: number;
} 

