import { CSSProperties, ReactNode } from "react";

import Paper from "@material-ui/core/Paper";
import clsx from "clsx";

interface ContainerCardProps {
  children: ReactNode;
  className?: string;
  modal?: boolean;
  padding?: number | string;
  backgroundColor?: string;
  style?: CSSProperties;
  onClick?: () => void;
  elevation?: number
}

function ContainerCard({
  children,
  className,
  modal = false,
  padding = 16,
  backgroundColor,
  style = {},
  onClick,
  elevation = 1
}: Readonly<ContainerCardProps>) {
  const dynamicStyle: CSSProperties = {
    padding,
    backgroundColor,
    maxHeight: modal ? "80vh" : undefined,
    overflowY: modal ? "scroll" : undefined,
    margin: "22px 0",
    ...style
  };

  return (
    <Paper className={clsx(className)} style={dynamicStyle} onClick={onClick} elevation={elevation}>
      {children}
    </Paper>
  );
}

export default ContainerCard;
